"use client"

import { useEffect, useState } from "react"
import { NotificationManager } from "react-notifications"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import axios from "axios"
import moment from "moment"
import useAuth from "../../hooks/useAuth"
import Loader from "../../components/Loader"
import Plus from "../../assets/svgs/Plus.svg"
import { BASE_URL } from "../../utils/Element"
import BaseLayout from "../../layouts/BaseLayout"
import ActionsMenu from "../../components/ActionsMenu"
import swap from "../../assets/svgs/swap-vertical.svg"
import sortAsce from "../../assets/svgs/sort-ascending.svg"
import sortDesc from "../../assets/svgs/sort-descending.svg"
import { initialPipelineFilterData } from "../../utils/initialData"
import ContactPagination from "../../components/Pagination/ContactPagination"
import DeleteConfirmationModal from "../../components/ConfirmationModals/DeleteConfirmationModal"
import LeadDataContainer from "../../pages/Leads/components/LeadDataContainer"
import PipelineFilter from "./PipelineFilter"
import AddPiplineModal from "./Modal/AddPiplineModal"
import EditpipelineModal from "./Modal/EditpipelineModal"
import PyramidChart from "./Component/PyramidChart"
import DispositionsFunnelChart from "./Component/DispositionsFunnelChart"
import StageDashboard from "./Component/StageDashboard"
import StageDispositionDashboard from "./Component/StageDahboradDisposition"
import ConfirmationModalBov from "../../components/ConfirmationModals/ConfirmationModalBov"

const Pipeline = () => {
  const [config] = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState(true)
  const [leadsData, setLeadsData] = useState([])
  const [selectedId, setSelectedId] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [pipelineDashboard, setPipelineDashboard] = useState()
  const [itemPerPage, setItemPerPage] = useState(50)
  const [sortBy, setSortBy] = useState("id")
  const [sortDirection, setSortDirection] = useState("asc")
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [disableDelete, setDisableDelete] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [activeStageTab, setActiveStageTab] = useState("Acquisition")
  const [showConfirmModalBov, setShowConfirmModalBov] = useState(false)
  const isSidebarCollapsed = useSelector((state) => state.isSidebarCollapsed)
  const [paginationData, setPaginationData] = useState({ totalItems: 0, from: 0, to: 0, totalPages: 0 })
  const queryParams = new URLSearchParams(location.search)
  const [filterData, setFilterData] = useState(() => {
    const localFilterData = localStorage.getItem("filterData")
    return localFilterData ? JSON.parse(localFilterData) : initialPipelineFilterData
  })
  const [filterStatus, setFilterStatus] = useState({
    total_pipeline: false,
    lead_not_contacted: false,
    buyer_not_contacted: false,
    pipeline_bov_presented_count: false,
    total_potential_gci: false,
    new_lead: false,
    buyer_making_offers: false,
    executed_loi: false,
    under_contract: false,
    new_lead_dispositions: false,
    bov_presented: false,
    exclusive_listing: false,
    executed_loi_dispositions: false,
    under_contract_dispositions: false,
  })

  const handleNext = () => {
    if (currentPage !== paginationData.totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const buildQueryParams = (filters) => {
    let params = `&page=${currentPage}&per_page=${itemPerPage}&search=${search}`

    if (search) {
      const encodedSearch = encodeURIComponent(search)
      params += `&search=${encodedSearch}`
    }
    const {
      contact_id,
      associate_id,
      transaction_type,
      stage,
      min_potential_gcl,
      max_potential_gcl,
      total_pipeline,
      lead_not_contacted,
      buyer_not_contacted,
      pipeline_bov_presented_count,
      total_potential_gci,
      new_lead,
      buyer_making_offers,
      executed_loi,
      under_contract,
      new_lead_dispositions,
      bov_presented,
      exclusive_listing,
      executed_loi_dispositions,
      under_contract_dispositions
    } = filters

    if (contact_id) {
      params += `&contact_id=${encodeURIComponent(contact_id)}`
    }
    if (associate_id) {
      params += `&associate_id=${encodeURIComponent(associate_id)}`
    }
    if (transaction_type?.length > 0) {
      params += `&transaction_type=${encodeURIComponent(transaction_type)}`
    }
    if (stage) {
      params += `&stage=${encodeURIComponent(stage)}`
    }
    if (min_potential_gcl) {
      params += `&min_potential_gcl=${encodeURIComponent(min_potential_gcl)}`
    }
    if (max_potential_gcl) {
      params += `&max_potential_gcl=${encodeURIComponent(max_potential_gcl)}`
    }
    if (sortDirection && sortBy !== "id") {
      params += `&sort_direction=${sortDirection}`
    }
    if (sortBy !== "id" && sortBy !== undefined) {
      params += `&sort_by=${sortBy}`
    }
    if (total_pipeline) {
      params += `&total_pipeline=true`
    }
    if (lead_not_contacted) {
      params += `&lead_not_contacted=true`
    }
    if (buyer_not_contacted) {
      params += `&buyer_not_contacted=true`
    }
    if (pipeline_bov_presented_count) {
      params += `&pipeline_bov_presented_count=true`
    }
    if (total_potential_gci) {
      params += `&total_potential_gci=true`
    }
    if (new_lead) {
      params += `&new_lead=true`
    }
    if (buyer_making_offers) {
      params += `&buyer_making_offers=true`
    }
    if (executed_loi) {
      params += `&executed_loi=true`
    }
    if (under_contract) {
      params += `&under_contract=true`
    }
    if (new_lead_dispositions) {
      params += `&new_lead_dispositions=true`
    }
    if (bov_presented) {
      params += `&bov_presented=true`
    }
    if (exclusive_listing) {
      params += `&exclusive_listing=true`
    }
    if (executed_loi_dispositions) {
      params += `&executed_loi_dispositions=true`
    }
    if (under_contract_dispositions) {
      params += `&under_contract_dispositions=true`
    }

    return params
  }

  const fetchPipeline = (filters) => {
    const queryParams = buildQueryParams({
      ...filters,
      total_pipeline: filterStatus.total_pipeline,
      lead_not_contacted: filterStatus.lead_not_contacted,
      buyer_not_contacted: filterStatus.buyer_not_contacted,
      pipeline_bov_presented_count: filterStatus.pipeline_bov_presented_count,
      total_potential_gci: filterStatus.total_potential_gci,
      new_lead:filterStatus.new_lead,
      buyer_making_offers:filterStatus.buyer_making_offers,
      executed_loi: filterStatus.executed_loi,
      under_contract: filterStatus.under_contract,
      new_lead_dispositions: filterStatus.new_lead_dispositions,
      bov_presented: filterStatus.bov_presented,
      exclusive_listing: filterStatus.exclusive_listing,
      executed_loi_dispositions: filterStatus.executed_loi_dispositions,
      under_contract_dispositions: filterStatus.under_contract_dispositions,
    })
    axios
      .get(`${BASE_URL}/pipelines?active_tab=${activeTab}${queryParams}`, config)
      .then((res) => {
        const value = res?.data?.pipelinesList
        setLeadsData(value?.data || [])
        setPaginationData({
          totalItems: value?.total ?? 0,
          from: value?.from ?? 0,
          to: value?.to ?? 0,
          totalPages: value?.last_page ?? 0,
        })
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message)
        }
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchPipeline(filterData)
  }, [sortDirection, sortBy, filterStatus])

  useEffect(() => {
    onSuccess()
  }, [search, currentPage, itemPerPage])

  const onSuccess = (filters) => {
    setLoading(true)
    if (filters) {
      fetchPipeline(filters)
    } else {
      fetchPipeline(filterData)
    }
  }

  const handleDelete = () => {
    setDisableDelete(true)
    axios
      .delete(`${BASE_URL}/delete-pipeline/${selectedId}`, config)
      .then((res) => {
        setSelectedId("")
        onSuccess()
        setShowDeleteModal(false)
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message)
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message)
        }
      })
      .finally(() => setDisableDelete(false))
  }

  const handleConvertBov = () => {
    axios
      .get(`${BASE_URL}/bov-convert/${selectedId}`, config)
      .then((res) => {
        setSelectedId("")
        onSuccess()
        setShowConfirmModalBov(false)
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message)
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message)
        }
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    if (queryParams) {
      queryParams.set("search", search)
    } else {
      queryParams.delete("search")
    }
    navigate({ search: queryParams.toString() }, { replace: true })
  }, [search, navigate, location.search])

  const fetchDashboardData = () => {
    setLoading(true)

    axios
      .get(`${BASE_URL}/dashboard-pipeline?active_tab=${activeTab}`, config)
      .then((res) => {
        setPipelineDashboard(res?.data?.pipeline_dashboard)
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message)
        }
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchDashboardData()
  }, [activeTab])

  return (
    <BaseLayout>
      <div className="contact-header">
        <p className="head-1 dark-H">Pipeline</p>
        <div className="md:flex block gap-4 items-center">
          <div className="flex gap-4 justify-between items-center">
            <PipelineFilter
              filterData={filterData}
              onSetFilterData={(value) => {
                setFilterData(value)
              }}
              from="Pipeline"
              onCallApiAgain={(filters) => onSuccess(filters)}
            />
            <div className="search-box contacts">
              <input
                type="text"
                className="body-S"
                placeholder="Search contact name"
                value={search}
                onChange={(e) => {
                  const newValue = e.target.value
                  setSearch(newValue.startsWith(" ") ? newValue.trimStart() : newValue)
                }}
                style={{ border: "1px solid #D8D8D8", marginTop: "0px" }}
              />
            </div>

            {(activeTab === 3 || activeTab === 5) && (
              <button onClick={() => setShowAddModal(true)} className="add-contact-button green-bg-H light-L body-S">
                <img className="mr-2" src={Plus || "/placeholder.svg"} alt="plus" /> Add
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="task-tabs mx-1 mt-2 flex justify-between body-L dark-M mb-4">
        <div className="flex gap-4">
          {["Team Dashboard", "Team Stage ", "My Dashboard", "Team List", "My Stage", "My list"].map((tab, index) => (
            <p
              key={index}
              role="button"
              className={`${activeTab === index ? "head-4 dark-H active" : ""} py-1`}
              onClick={() => {
                setActiveTab(index)
                setCurrentPage(1)
                setFilterStatus({
                  total_pipeline: false,
                  lead_not_contacted: false,
                  buyer_not_contacted: false,
                  pipeline_bov_presented_count: false,
                  total_potential_gci: false,
                  new_lead: false,
                  buyer_making_offers: false,
                  executed_loi: false,
                  under_contract: false,
                  new_lead_dispositions: false,
                  bov_presented: false,
                  exclusive_listing: false,
                  executed_loi_dispositions: false,
                  under_contract_dispositions: false,
                })
              }}
            >
              {tab}
            </p>
          ))}
        </div>
      </div>

      {(activeTab === 3 || activeTab === 5) && (
        <div className="light-bg-L pb-3 table-container">
          <div className={`${isSidebarCollapsed ? "collapsed-width" : "width"} table-info light-bg-L`}>
            {loading ? (
              <Loader />
            ) : leadsData?.length === 0 ? (
              <p className="body-N text-center mt-6 p-10">No Pipline Available</p>
            ) : (
              <table className="contact-table light-bg-L">
                <thead>
                  <tr className="uppercase body-N dark-M">
                    <th className="green-H">
                      <div className="table-header">
                        DATE ADDED
                        <img
                          role="button"
                          src={sortBy !== "bds" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          onClick={() => {
                            setSortBy("bds")
                            if (sortDirection === "desc") {
                              setSortDirection("asc")
                            } else {
                              setSortDirection("desc")
                            }
                          }}
                          alt="icon"
                        />
                      </div>
                    </th>
                    <th>
                      <div className="table-header">
                        LAST CONTACTED
                        <img
                          role="button"
                          src={sortBy !== "broker" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          alt="icon"
                          onClick={() => {
                            setSortBy("broker")
                            if (sortDirection === "desc") {
                              setSortDirection("asc")
                            } else {
                              setSortDirection("desc")
                            }
                          }}
                        />
                      </div>
                    </th>

                    <th>
                      <div className="table-header">
                        CONTACT NAME
                        <img
                          role="button"
                          src={sortBy !== "created_at" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          alt="icon"
                          onClick={() => {
                            setSortBy("created_at")
                            if (sortDirection === "desc") {
                              setSortDirection("asc")
                            } else {
                              setSortDirection("desc")
                            }
                          }}
                        />
                      </div>
                    </th>

                    <th>
                      <div className="table-header">
                        TRANSACTION TYPE
                        <img
                          role="button"
                          src={sortBy !== "transaction_type" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          alt="icon"
                          onClick={() => {
                            setSortBy("transaction_type")
                            if (sortDirection === "desc") {
                              setSortDirection("asc")
                            } else {
                              setSortDirection("desc")
                            }
                          }}
                        />
                      </div>
                    </th>

                    <th>
                      <div className="table-header">
                        STAGE
                        <img
                          role="button"
                          src={sortBy !== "stage" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          alt="icon"
                          onClick={() => {
                            setSortBy("stage")
                            if (sortDirection === "desc") {
                              setSortDirection("asc")
                            } else {
                              setSortDirection("desc")
                            }
                          }}
                        />
                      </div>
                    </th>

                    <th>
                      <div className="table-header">
                        POTENTIAL GCI
                        <img
                          role="button"
                          src={sortBy !== "contact_id" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          alt="icon"
                          onClick={() => {
                            setSortBy("contact_id")
                            if (sortDirection === "desc") {
                              setSortDirection("asc")
                            } else {
                              setSortDirection("desc")
                            }
                          }}
                        />
                      </div>
                    </th>

                    <th>
                      <div className="table-header">
                        ASSOCIATE
                        <img
                          role="button"
                          src={sortBy !== "type" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          alt="icon"
                          onClick={() => {
                            setSortBy("type")
                            if (sortDirection === "desc") {
                              setSortDirection("asc")
                            } else {
                              setSortDirection("desc")
                            }
                          }}
                        />
                      </div>
                    </th>
                    <th>
                      <div className="table-header">Actions</div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {leadsData?.map((el, idx) => (
                    <tr key={idx} className="dark-H body-N">
                      <td className="dark-M">{moment(el?.created_at).tz("America/New_York").format("MM/DD/YY")}</td>
                      <td className="dark-M">
                        {el?.last_update_date
                          ? moment(el?.last_update_date).tz("America/New_York").format("MM/DD/YY")
                          : ""}{" "}
                        <span className="dark-M body-S">
                          {el?.last_update_date ? ` (${moment(el?.last_update_date).fromNow()})` : ""}
                        </span>
                      </td>

                      <td className="capitalize head-5">
                        <Link
                          to={`/contact/${el?.contact?.id}`}
                          onClick={(e) => {
                            e.preventDefault()
                            if (el?.contact?.id) {
                              navigate(`/contact/${el?.contact?.id}`)
                            }
                          }}
                          rel="noopener noreferrer"
                        >
                          {el?.contact?.first_name} {el?.contact?.last_name}
                        </Link>
                      </td>
                      <td className="dark-M">{el?.transaction_type_name}</td>
                      <td className="dark-M">{el?.stage_name}</td>
                      <td className="dark-M">{el?.potential_gcl}</td>
                      <td className="capitalize head-5">
                        <Link
                          to={`/user/${el?.associate_id}`}
                          onClick={(e) => {
                            e.preventDefault()
                            if (el?.contact?.id) {
                              navigate(`/user/${el?.associate_id}`)
                            }
                          }}
                          rel="noopener noreferrer"
                        >
                          {el?.associate?.first_name} {el?.associate?.last_name}
                        </Link>
                      </td>

                      <td className="flex gap-3 items-center w-full justify-center">
                        <ActionsMenu
                          from="pipeline"
                          handleEdit={() => {
                            setShowEditModal(true)
                            setSelectedId(el?.id)
                          }}
                          handleDelete={() => {
                            setSelectedId(el?.id)
                            setShowDeleteModal(true)
                          }}
                          otherBovTitle={"Convert to BOV"}
                          handleConvertBov={() => {
                            setShowConfirmModalBov(true)
                            setSelectedId(el?.id)
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          {leadsData?.length > 0 && (
            <ContactPagination
              activeTab={activeTab}
              paginationData={paginationData}
              handleNext={handleNext}
              handlePrev={handlePrev}
              onSuccess={onSuccess}
              itemPerPage={itemPerPage}
              handleItemPerPageSelect={(value) => {
                setItemPerPage(value)
                setCurrentPage(1)
              }}
            />
          )}
        </div>
      )}

      {(activeTab === 0 || activeTab === 2) && (
        <>
          <div className="flex items-stretch gap-3">
            <LeadDataContainer
              text="TOTAL CONTACTS IN PIPELINE"
              number={pipelineDashboard?.pipeline_contact_count}
              className="h-40 w-full"
              onClick={() => {
                setActiveTab(3)
                setCurrentPage(1)
                setFilterStatus({
                  total_pipeline: true,
                  lead_not_contacted: false,
                  buyer_not_contacted: false,
                  pipeline_bov_presented_count: false,
                  total_potential_gci: false,
                })
              }}
            />

            <LeadDataContainer
              text="TOTAL NEW LEADS NOT CONTACTED IN 14+ DAYS"
              number={pipelineDashboard?.pipeline_new_lead_count}
              className="h-40 w-full"
              onClick={() => {
                setActiveTab(3)
                setCurrentPage(1)
                setFilterStatus({
                  total_pipeline: false,
                  lead_not_contacted: true,
                  buyer_not_contacted: false,
                  pipeline_bov_presented_count: false,
                  total_potential_gci: false,
                })
              }}
            />

            <LeadDataContainer
              text="TOTAL BUYERS MAKING OFFERS NOT CONTACTED IN 14+ DAYS"
              number={pipelineDashboard?.pipeline_buyer_making_offers_count}
              className="h-40 w-full"
              onClick={() => {
                setActiveTab(3)
                setCurrentPage(1)
                setFilterStatus({
                  total_pipeline: false,
                  lead_not_contacted: false,
                  buyer_not_contacted: true,
                  pipeline_bov_presented_count: false,
                  total_potential_gci: false,
                })
              }}
            />
            <LeadDataContainer
              text="TOTAL BOV PRESENTED NOT CONTACTED IN 14+ DAYS"
              number={pipelineDashboard?.pipeline_bov_presented_count}
              className="h-40 w-full"
              onClick={() => {
                setActiveTab(3)
                setCurrentPage(1)
                setFilterStatus({
                  total_pipeline: false,
                  lead_not_contacted: false,
                  buyer_not_contacted: false,
                  pipeline_bov_presented_count: true,
                  total_potential_gci: false,
                })
              }}
            />
            <LeadDataContainer
              text="TOTAL POTENTIAL GCI"
              number={pipelineDashboard?.bov_potential_gcl}
              className="h-40 w-full"
              onClick={() => {
                setActiveTab(3)
                setCurrentPage(1)
                setFilterStatus({
                  total_pipeline: false,
                  lead_not_contacted: false,
                  buyer_not_contacted: false,
                  pipeline_bov_presented_count: false,
                  total_potential_gci: true,
                })
              }}
            />
          </div>

          <div className="px-4 flex justify-center items-start gap-4 bg-white pb-4">
            <div className="w-[50%]">
              <PyramidChart
                Title="TOTAL ACQUISITIONS"
                Total={pipelineDashboard?.pipeline_status_acquisition_count}
                activeTab={activeTab}
                onClick={(filterKeys) => {
                  if (!filterKeys) {
                    setFilterStatus({
                      new_lead: false,
                      buyer_making_offers: false,
                      executed_loi: false,
                      under_contract: false,
                    })
                  } else {
                    setActiveTab(3) 
                    setCurrentPage(1)
                    setFilterStatus({
                      ...filterStatus,
                      ...filterKeys,
                    })
                  }
                }}
              />
            </div>

            <div className="w-[50%] ">
              <DispositionsFunnelChart
                Title="TOTAL DISPOSITIONS"
                Total={pipelineDashboard?.pipeline_status_disposition_count}
                activeTab={activeTab}
                onClick={(filterKeys) => {
                  if (!filterKeys) {
                    setFilterStatus({
                      new_lead_dispositions: false,
                      bov_presented: false,
                      exclusive_listing: false,
                      executed_loi_dispositions: false,
                      under_contract_dispositions: false,
                    })
                  } else {
                    setActiveTab(3) 
                    setCurrentPage(1)
                    setFilterStatus({
                      ...filterStatus,
                      ...filterKeys,
                    })
                  }
                }}
              />
            </div>
          </div>
        </>
      )}

      {(activeTab === 1 || activeTab === 4) && (
        <div>
          <div className="mt-3">
            <div className="flex justify-between header-tabs rounded-full bg-white p-1 max-w-xs mx-auto">
              <button
                onClick={() => setActiveStageTab("Acquisition")}
                className={`${
                  activeStageTab === "Acquisition" ? "green-bg-H text-white font-medium" : "text-gray-600"
                } px-4 py-2 rounded-full flex-1 text-sm transition-all`}
              >
                Acquisitions
              </button>
              <button
                onClick={() => setActiveStageTab("Disposition")}
                className={`${
                  activeStageTab === "Disposition" ? "green-bg-H text-white font-medium" : "text-gray-600"
                } px-4 py-2 rounded-full flex-1 text-sm transition-all`}
              >
                Dispositions
              </button>
            </div>
          </div>

          <div className="p-4">
            {activeStageTab === "Acquisition" && <StageDashboard activeTab={activeTab} />}
            {activeStageTab === "Disposition" && <StageDispositionDashboard activeTab={activeTab} />}
          </div>
        </div>
      )}

      <AddPiplineModal showModal={showAddModal} onClose={() => setShowAddModal(false)} onCallApi={onSuccess} />

      <EditpipelineModal
        showModal={showEditModal}
        onClose={() => setShowEditModal(false)}
        id={selectedId}
        onCallApi={() => {
          setSelectedId("")
          onSuccess()
        }}
      />

      <ConfirmationModalBov
        showModal={showConfirmModalBov}
        onClose={() => setShowConfirmModalBov(false)}
        handleUnderContract={handleConvertBov}
      />

      <DeleteConfirmationModal
        showModal={showDeleteModal}
        disable={disableDelete}
        onClose={() => setShowDeleteModal(false)}
        handleDelete={handleDelete}
      />
    </BaseLayout>
  )
}

export default Pipeline

