import React, { useEffect, useState } from "react";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import Loader from "../../../components/Loader";
import { BASE_URL } from "../../../utils/Element";
import Modal from "../../../components/Modal/Modal";
import { NotificationManager } from "react-notifications";
import AddpipelineData from "../Component/AddPipelineData";

const initialData = {
    associate_id:"",
    contact_id:"",
    transaction_type:"",
    stage:"",
    potential_gcl:"",
};

const EditpipelineModal = ({ showModal, onClose, id,  onCallApi }) => {
  const [config] = useAuth();
   const [addpipelineError, setAddpipelineError] = useState({ pipelinename: "" });
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [addpipelineData, setAddpipelineData] = useState(initialData);

  const handleClose = () => {
    setAddpipelineData(initialData);
    onClose();
    setAddpipelineError();
    setDisable(false);
  };

  useEffect(() => {
    if (id && showModal) {
      setLoading(true);
      axios
        .get(`${BASE_URL}/getby-id-pipeline/${id}`, config)
        .then((res) => {
          const data = res?.data?.LeadsList;
          setAddpipelineData({
            contact_id: data.contact_id,
            associate_id: data.associate_id,
            transaction_type: data.transaction_type,
            stage: data.stage,
            potential_gcl: data.potential_gcl,
          });
        })
        .catch((err) => {
          if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
            NotificationManager.error(err.response?.data?.message);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [id, showModal]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisable(true);
    const dataToSend = {
        contact_id: addpipelineData.contact_id,
        associate_id: addpipelineData.associate_id,
        transaction_type: addpipelineData.transaction_type,
        stage: addpipelineData.stage,
        potential_gcl: addpipelineData.potential_gcl,
    };

    axios
      .put(`${BASE_URL}/update-pipeline/${id}`, dataToSend, config)
      .then((res) => {
        handleClose();
        onCallApi();
      })
      .catch((err) => {
        setDisable(false);
        setAddpipelineError(err.response?.data?.errors);
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };


  const handleChange = (value, name) => {
    setAddpipelineData({ ...addpipelineData, [name]: value });
    if (name === "pipelineName") {
      setAddpipelineError({ ...addpipelineError, pipelinename: "" });
    }
  };
  return (
    <Modal title={`Edit Pipeline`} desc={`Edit the Pipeline information.`} show={showModal} onClose={handleClose}>
      {loading ? (
        <Loader />
      ) : (
        <AddpipelineData
        addpipelineData={addpipelineData}
        onSetAddpipelineData={(value) => {
          setAddpipelineData(value);
        }}
        handleSubmit={handleSubmit}
        showModal={showModal}
        handleChange={handleChange}
        disable={disable}
        onClose={handleClose}
      />
      )}
    </Modal>
  );
};

export default EditpipelineModal;
