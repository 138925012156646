import React from "react";
import { Link } from "react-router-dom";

const LeadDataContainer2 = ({ text, data = [], columns }) => {
  return (
    <div>
      <div style={{ padding: "30px" }} className="text-center mb-4 light-bg-L">
        <p className="head-2 green-H">{text}</p>
        <div className="py-3 overflow-y-auto" style={{ maxHeight: "150px" }}>
          {data.length > 0 ? (
            <div className="grid grid-cols-2 gap-4 text-left">
              {columns.map((col, index) => (
                <div key={index} className="head-5 dark-H">
                  {col.label}
                </div>
              ))}
              {data.map((item, index) => (
                 <React.Fragment key={index}>
                 {columns.map((col, colIndex) => (
                   <div key={colIndex} className="body-S green-H underline ">
                     {col.key === "contact_name" ? (
                       <Link to={`/contact/${item.contact_id_map}`}>
                         {item[col.key] || "N/A"}
                       </Link>
                     ) : col.key === "link_name" ? (
                       <Link to={`/property/${item.link_map_id}`}>
                         {(item.link_name && (item.link_type === "acquisition" || item.link_type === "property"))
                           ? item.link_name
                           : item.link_name || "N/A"}
                       </Link>
                     ) : (
                       item[col.key] || "N/A"
                     )}
                   </div>
                 ))}
               </React.Fragment>
              ))}
            </div>
          ) : (
            <p className="head-5 dark-H p-0">N/A</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeadDataContainer2;
