export const initialPropertyFilterData = {
  propertyName: "",
  storeId: "",
  propertyType: "",
  streetAddress: "",
  city: "",
  state: "",
  zip: "",
  lastUpdateOption: "date",
  last_update_date: "",
  last_update_start_date: "",
  last_update_end_date: "",
  lastUpDateCategory: "days",
  lastcontactCategory: "days",
  minCaprate: "",
  maxCaprate: "",
  minPrice: "",
  maxPrice: "",
  minBuilding: "",
  maxBuilding: "",
  minLand: "",
  maxLand: "",
  owner_id:"",
  count:"",
  start_count:"",
  end_count:"",
  without_property_tags:"",
  lastSoldOption: "date",
  lastSoldSentDate: "",
  lastSoldStartDate: "",
  lastSoldEndDate: "",
  lastSoldCategory: "days",
  lastSoldWithinLastValue: "",
  lastSoldLongerThanValue: "",
  tags: [],
  hasOwner: "",
  ownerPhoneCheck: "",
  ownerPhone: "",
  ownerStreetAddressCheck: "",
  ownerStreetAddress: "",
  ownerTaxRecordCheck: "",
  taxRecordSentDate: "",
  taxRecordSentStartDate: "",
  taxRecordSentEndDate: "",
  ownerWebsite: "",
  ownerTags: [],
  ownerType: "",
  owner: { id: "", name: "" },
  sort_direction:"",
  sort_by:"",
  last_contact: "date",
  last_update_start_contact_date: "",
  last_update_contact_date: "",
  last_update_end_contact_date: "",
  interaction_type:"",
  max_sold_cap_rate:"",
  min_sold_cap_rate:"",
  max_sold_price:"",
  min_sold_price: "",
  within_last_day: "",
  longer_than_day: "",
  within_last_contact_day: "",
  longer_than_contact_day: "",
 };

export const initialBuyerFilterData = {
  availability_status: "",
  buyer_status: [],
  min_asking_cap_rate: "",
  min_price: "",
  max_price: "",
  landlord_responsibilities: [],
  property_type: [],
  min_lease_term_reamaining: "date",
  lease_date: "",
  lease_start_date: "",
  lease_end_date: "",
  lease_date_category: "days",
  lease_within_last_day: "",
  lease_longer_than_day: "",
  last_update: "date",
  last_update_date: "",
  last_update_start_date: "",
  last_update_end_date: "",
  date_category: "days",
  state: [],
  tenant_name: "",
  sort_direction:"",
  sort_by:"",
  min_price_sf :"",
  max_price_sf :"",
  min_rent_sf :"",
  max_rent_sf :"",
  min_total_gla :"",
  max_total_gla :"",
  min_demographics :"",
  max_demographics :"",
  min_traffic_count :"",
  max_traffic_count :"",
};

export const initialTaskFilterData = {
  selectedPriorities: [],
  isRepeatingTask: "",
  selectedTaskTypes: [],
  selectedLinkedOptions: [],
  selectedDueDate: "",
};

export const initialContactFilterData = {
  first_name: "",
  last_name: "",
  email: "",
  company: { id: "", name: "" },
  property: "",
  phone: "",
  street_address: "",
  city: "",
  state: "",
  zip_code: "",
  leadsource_id: [],
  website: "",
  first_deal_anniversary: "",
  has_acquisition: "",
  tax_record_sent: "",
  tax_record_sent_date: "",
  tax_record_start_date: "",
  tax_record_end_date: "",
  tags: [],
  tag_category: "with",
  last_contact: "date",
  within_last_day: "",
  longer_than_day: "",
  lastContactSentDate: "",
  lastContactSentSdate: "",
  lastContactSentEdate: "",
  date_category: "days",
  count: "",
  start_count: "",
  end_count: "",
  interaction_type: "",
  sort_direction:"",
  sort_by:"",
  interaction_type_user:""
};

export const initialCompanyFilterData = {
  company_name: "",
  primaryphone: "",
  streetaddress: "",
  city: "",
  state: "",
  zip: "",
  website: "",
  taxrecord: "",
  taxrecordsentdate: "",
  taxrecordsentSdate: "",
  taxrecordsentEdate: "",
  tags: [],
  last_contact: "date",
  lastContactSentDate: "",
  lastContactSentSdate: "",
  lastContactSentEdate: "",
  date_category: "days",
  longer_than_day: "",
  within_last_day: "",
  count: "",
  start_count: "",
  end_count: "",
  property: "",
  sort_direction:"",
  sort_by:""
};

export const initialPipelineFilterData = {
  transaction_type: "",
  contact_id: "",
  date: "",
  created_start_date: "",
  created_end_date: "",
  min_potential_gcl: "",
  max_potential_gcl: "",
  stage: "",
};

export const initialSmartPlanFilterData = {
  touches: "",
  duration: "",
  priority: [],
  contacts: [],
  task_type: "",
  repeat_number: "",
  link_other_smartplan: [],
};

export const initialLeadFilterData = {
  bds: "",
  broker: "",
  lead_source: [],
  created_at: "",
  contact: { id: "", name: "" },
  property: { id: "", name: "" },
  lead_type: "",
  buyer_id: "",
  link_id: "",
  created_start_date: "",
  created_end_date: "",
  date_category: "days",
  last_contact: "date",
  within_last_day:"",
  longer_than_day:"",
  last_contact: "date",
  lastContactSentDate: "",
  lastContactSentSdate: "",
  lastContactSentEdate: "",
};

export const initialProposalFilterData = {
  bds: "",
  broker: "",
  created_at: "",
  lead_type: "",
  contact: { id: "", name: "" },
  property: { id: "", name: "" },
  buyer_id: "",
  link_id: "",
  status: "",
  created_start_date: "",
  created_end_date: "",
};

export const initialOfferFilterData = {
  associate: "",
  property_name: "",
  property_address: "",
  city: "",
  state: "",
  zip_code: "",
  offer_from: "",
  deal_type: "",
  created_at: "",
  offer_type: "",
  noi: "",
  offer_cap_rate: "",
  offer_price: "",
  min_asking_cap_rate: "",
  max_asking_cap_rate: "",
  min_asking_price: "",
  max_asking_price: "",
  percent_of_asking_price: "",
  created_start_date: "",
  created_end_date: "",
  contact: { id: "", name: "" },
};
