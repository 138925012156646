import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import { BASE_URL } from "../../../utils/Element";
import Select from "react-select";
import { NotificationManager } from "react-notifications";

const AddpipelineData = ({ addpipelineData, handleSubmit, showModal ,handleChange, onClose, disable, error ,  }) => {
  const [bdsOptions, setBdsOptions] = useState([]);
  const [contactOptions, setContactOptions] = useState([]);
  const [transactionOptions, setTransactionOptions] = useState([]);
  const [stageOptions, setStageOptions] = useState([]);
  const selectRef = useRef(null);
  const [config] = useAuth();

  const fetchUsers = (listType) => {
    axios
      .get(`${BASE_URL}/get-datalisting?list=${listType}`, config)
      .then((res) => {
        const data = res?.data?.data;
        const userOptions = data?.users.map((el) => ({
          value: el?.id,
          label: `${el?.first_name} ${el?.last_name}`,
          role: el?.role_id,
        }));
        const contactOptions = data?.contact_list?.map((el) => ({
          value: el?.id,
          label: `${el?.name}`,
        }));
        const transactionOptions = data?.transaction_type?.map((el) => ({
          value: el?.id,
          label: `${el?.name}`,
        }));
        const stageOptions = data?.stage?.map((el) => ({
          value: el?.id,
          label: `${el?.name}`,
        }));


        switch (listType) {
          case "usersList&role_id=3":
            setBdsOptions(userOptions);
            break;
          case "contacts":
            setContactOptions(contactOptions);
            break;
          case "transaction_type":
            setTransactionOptions(transactionOptions);
            break;
          case "stage":
            setStageOptions(stageOptions);
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  useEffect(() => {
    if (showModal) {
      fetchUsers("usersList&role_id=3");
      fetchUsers("contacts");
      fetchUsers("transaction_type");
      fetchUsers("stage");
    }
  }, [showModal]);

  return (
    <div>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div>
          <label className="dark-H head-4 mb-2">Associate<span className="red-D">*</span></label>
          <Select
            ref={selectRef}
            className="body-N"
            options={bdsOptions}
            placeholder="Select"
            value={bdsOptions.find((option) => option?.value === addpipelineData?.associate_id) || null}
            onChange={(option) => handleChange(option?.value, "associate_id")}
          />
          {error?.associate_id && <p className="body-S red-D pt-1">{error?.associate_id}</p>}
        </div>

        <div className="mt-6">
          <label className="dark-H head-4 mb-2">
            Contact Name<span className="red-D">*</span>
          </label>
          <Select
            ref={selectRef}
            className="body-N"
            options={contactOptions}
            placeholder="Select"
            value={contactOptions.find((option) => option?.value === addpipelineData?.contact_id) || null}
            onChange={(option) => handleChange(option?.value, "contact_id")}
          />
          {error?.contact_id && <p className="body-S red-D pt-1">{error?.contact_id}</p>}
        </div>

        <div className="mt-6">
          <label className="dark-H head-4">Transaction Type</label>
          <div className="mt-3">
          <Select
            options={transactionOptions}
            className="body-N"
            value={transactionOptions.find((option) => option.value === addpipelineData?.transaction_type) || null}
            onChange={(selectedOption) => handleChange(selectedOption?.value, "transaction_type")}
            placeholder="Select transaction type"
          />
           </div>
        </div>

        <div className="mt-6">
          <label className="dark-H head-4">Stage</label>
          <div className="mt-3">
            <Select
              options={stageOptions}
              className="body-N"
              value={stageOptions.find((option) => option.value === addpipelineData?.stage) || null}
              onChange={(option) => handleChange(option?.value, "stage")}
              placeholder="Select stage type"
            />
          </div>
        </div>

        <div className="mt-6">
          <label className="dark-H head-4 mb-2">Potential GCI</label>
          <input
            className="body-N"
            name="potential_gcl"
            type="number"
            placeholder="Write Potential GCI Here"
            value={addpipelineData.potential_gcl ?? ""}
            onChange={(e) => handleChange(e.target.value, "potential_gcl")}
          />
          {error?.potential_gcl && <p className="body-S red-D pt-1">{error?.potential_gcl}</p>}
        </div>

        <div className="mt-6">
          <button type="submit" disabled={disable} className="save-button light-L head-5 green-bg-H">
            Save
          </button>
          <button type="button" onClick={onClose} className="green-H ml-5">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddpipelineData;
