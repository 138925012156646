import React, { useEffect, useRef, useState } from "react";
import { BASE_URL } from "../../utils/Element";
import Modal from "../Modal/Modal";
import gif from "../../assets/gif/inbox.gif";
import { Radio, RadioGroup,Stack } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import Select from "react-select";
import { Navigate } from "react-router-dom";

const initialData = {
  bds: null,       
  broker: null,    
  created_at: "",
  lead_source: null,
  lead_type: "",
  contact_id:"",
  link: "",
};

const MarkAsLeadConfirmationModal = ({ showModal, from , onClose, handleAction, isLead ,contactDetails}) => {
  const [value, setValue] = useState();
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  const [config] = useAuth();
   const [loading, setLoading] = useState(false);
  const [bdsOptions, setBdsOptions] = useState([]);
  const [linkOptions, setLinkOptions] = useState([]);
  const [brokerOptions, setBrokerOptions] = useState([]);
  const [formData, setFormData] = useState(initialData);
  const [error, setError] = useState();
  const selectRef = useRef(null);

  const handleConfirm = () => {
    console.log("Before sending to API, formData:", formData);  // Debug log
  
    const payload = {
      ...formData,
      lead_type: formData.lead_type === "acquisition" ? 1 : 0,  // Ensure it's correctly set
      type: from === "lead" ? 0 : 1,
    };
  
    console.log("Payload sent to API:", payload);  // Debug log
  
    handleAction(payload);
  };
  

  const handleChange = (value, name) => {
    setError((prev) => ({ ...prev, [name]: "" }));
    setFormData((prev) => ({ ...prev, [name]: value || null })); 
  };

  const fetchLeadSources = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/get-datalisting?list=lead_sources`, config)
      .then((res) => {
        const data = res?.data?.data;
        const options = data?.lead_sources?.map((el) => ({ value: el?.id, label: el.name }));
        setLeadSourceOptions(options);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const fetchUsers = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/get-datalisting?list=users`, config)
      .then((res) => {
        const data = res?.data?.data;
        const options = data?.map((el) => ({ value: el?.id, label: `${el?.first_name} ${el?.last_name}`, role: el?.role_id }));
        setBdsOptions(options);
        setBrokerOptions(options?.filter((el) => el.role === 3));
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const fetchLinks = () => {
    setLoading(true);
    axios
    .get(`${BASE_URL}/get-ContactData/${contactDetails?.id}`, config)
      .then((res) => {
        const data = res?.data?.data;
        const properties = data?.properties;
        const acquisitions = data?.acquisitions;
        const propertyOptions = properties?.map((el) => ({
          value: el?.id,
          label: el?.property_name,
          desc: `${el.street_address}, ${el.city}, ${el.state}`,
        }));
        const acquisitionOptions = acquisitions?.map((el) => ({
          value: el?.id,
          label: el?.property_type?.[0]?.type,
          minPrice: el?.min_price,
          maxPrice: el?.max_price,
        }));
        if (formData.lead_type === "acquisition") {
          setLinkOptions(acquisitionOptions);
        } else {
          setLinkOptions(propertyOptions);
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  

  const formatOptionLabel = ({ value, label, minPrice, maxPrice, desc }) => (
    <div>
      <div>{label}</div>
      {desc && <p className="dark-M">{desc}</p>}
      {(minPrice || maxPrice) && (
        <div className="flex gap-2 dark-M">
          {minPrice && <div>Min Price-{minPrice}</div>}/{maxPrice && <div>Max Price-{maxPrice}</div>}
        </div>
      )}
    </div>
  );

  const handleLeadTypeChange = (value) => {
    console.log("Selected lead type:", value); // Debug log
  
    setError({ ...error, lead_type: "" });
    setFormData({
      ...formData,
      lead_type: value, // Store "acquisition" or "disposition"
      link: "",
    });
  
    console.log("Updated lead_type in formData:", value);
  };

  

 useEffect(() => {
    if (showModal) {
      if (formData?.contact !== "") {
        fetchLinks();
      }
    }
  }, [showModal, formData?.contact, formData?.lead_type]);

  useEffect(() => {
    if (showModal) {
      fetchUsers();
      fetchLeadSources();
      setFormData(initialData);
    }
  }, [showModal]);

  return (
    <Modal show={showModal} onClose={onClose}>
      <form className="py-3">
        <div className="flex justify-center mt-[-20px]">
          <img src={gif} alt="" height={120} width={120} />
        </div>

        <div className="flex-1 mt-4 text-center w-30">
          <p className="head-1 dark-H font-bold">{isLead ? "Mark as" : "Unmark"} lead?</p>
          <p className="py-1 body-N dark-M mb-3">
            Are you sure to {isLead ? "mark as" : "unmark"} lead in this contact?
            This action can be reversed anytime.
          </p>
        </div>

        <div className="mt-3">

        <div className="flex mt-4 gap-4">
         <div className="w-[48%] mt-4">
           <label className="dark-H head-4 mb-2">BDS</label>
           <Select
             ref={selectRef}
             className="body-N"
             options={[{ label: "None", value: null }, ...bdsOptions]} // Added blank option
             placeholder="Select"
             value={bdsOptions.find(option => option?.value === formData.bds) || null} 
             onChange={(option) => handleChange(option?.value || null, "bds")} // Handle clearing
           />
         </div>

          <div className="w-[48%] mt-4">
            <label className="dark-H head-4 mb-2">Broker<span className="red-D">*</span></label>
            <Select
              ref={selectRef}
              className="body-N"
              options={[{ label: "None", value: null }, ...brokerOptions]} // Added blank option
              placeholder="Select"
              value={brokerOptions.find(option => option?.value === formData.broker) || null}
              onChange={(option) => handleChange(option?.value || null, "broker")} // Handle clearing
            />
          </div>
       </div>


        <div className="flex mt-4 gap-4">
          <div className="w-[48%] mt-3">
            <label className="dark-H head-4 mb-2">Lead Date</label>
            <DatePicker
              className="body-N"
              dateFormat="MM/dd/yyyy"
              placeholderText="MM/DD/YYYY"
              selected={formData.created_at ? new Date(formData.created_at) : null}
              onChange={(value) => handleChange(value, "created_at")}
            />
            {error?.created_at && <p className="body-S red-D">{error?.created_at}</p>}
          </div>

          <div className="w-[48%] mt-4">
             <label className="dark-H head-4 mb-2">
               Lead Source<span className="red-D">*</span>
             </label>
             <Select
               className="body-N"
               options={[{ label: "None", value: null }, ...leadSourceOptions]} // Added blank option
               placeholder="Select"
               value={leadSourceOptions?.find((option) => option.value === formData.lead_source) || null}
               onChange={(option) => handleChange(option?.value || null, "lead_source")} // Allow clearing
             />
             {error?.lead_source_id && <span className="body-S red-D">{error?.lead_source_id}</span>}
           </div>
          </div>
       
          <div className="flex gap-3 mt-4">
            <RadioGroup
              onChange={handleLeadTypeChange}
              value={formData?.lead_type} 
            >
              <Stack direction="row" gap={5}>
                <Radio value="disposition">Disposition</Radio>
                <Radio value="acquisition">Acquisition</Radio>
              </Stack>
            </RadioGroup>
          </div>

          {formData?.lead_type !== "" && (
              <div className="mt-6">
                <label className="dark-H head-4 mb-2 required:*:">
                  Property /Acquisition Criteria<span className="red-D">*</span>
                </label>
                <div className="mt-1.5">
                  <Select
                    className="body-N"
                    options={linkOptions}
                    formatOptionLabel={formatOptionLabel}
                    placeholder={"Select"}
                    value={linkOptions.find((option) => option.value === formData.link)}
                    onChange={(option) => {
                      setError({ ...error, link_id: "" });
                      handleChange(option.value, "link");
                    }}
                  />
                </div>
              </div>
            )}

         </div>

        <div className="mt-6 flex justify-center">
          <button type="button" onClick={handleConfirm} className="save-button light-L head-5 green-bg-H">
            Confirm
          </button>
          <button type="button" onClick={onClose} className="green-H body-N ml-5">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default MarkAsLeadConfirmationModal;
