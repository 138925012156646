import React, { useRef, useState, useEffect } from "react";
import Modal from "../../Modal/Modal";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import { BASE_URL } from "../../../utils/Element";
import { NotificationManager } from "react-notifications";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { activityOptions } from "../../../utils/options";
import { IoIosArrowDown as ArrowDown } from "react-icons/io";

const CompleteTaskModal = ({ showModal, onClose, taskData, onTaskCompleted }) => {
  const [config] = useAuth();
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [formData, setFormData] = useState({ interaction_type: "" });
  const [error, setError] = useState({});
  const [addactivityerror, setAddactivityerror] = useState("");

  // Populate fields when modal opens
  useEffect(() => {
    if (showModal && taskData) {
      console.log('taskData?.task_type',taskData?.task_type);
      setDescription(taskData?.description || "");
      setFormData({
        interaction_type:
          taskData?.task_type === "call"
            ? "Conversation"
            : taskData?.task_type === "Call"
            ? "Conversation"
            : taskData?.task_type === "task"
            ? "Text"
            : taskData?.task_type === "phone"
            ? "Conversation"
            : taskData?.task_type === "Task"
            ? "Text"
             : taskData?.task_type === "task"
            ? "Text"
            : taskData?.task_type === "mail"
            ? "Mail"
            : taskData?.task_type === "email"
            ? "Email"
            : taskData?.task_type === "Note"
            ? "Note"
            : taskData?.task_type || "",
      });
    }
  }, [showModal, taskData]);

  const handleAddActivity = () => {
    const currentDate = new Date().toISOString().split("T")[0];

    const dataToSend = {
      interaction_type: formData?.interaction_type,
      description: description,
      date: currentDate,
      contact_id: taskData?.contact_id,
      company_id: taskData?.company_id,
    };

    axios
      .post(`${BASE_URL}/add-activity`, dataToSend, config)
      .then((res) => {
        if (res?.data?.success) {
          setDescription("");
          setType("");
          onClose();
          onTaskCompleted();
        }
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  const handleCompleteTask = () => {
    axios
      .post(`${BASE_URL}/perform-Action?action=is_complete&task_id=${taskData?.id}`, {}, config)
      .then((res) => {
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
        setDescription("");
        setType("");
        onClose();
        onTaskCompleted();
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  const handleSubmit = () => {
    if (type === "yes") {
      handleAddActivity();
    }
    handleCompleteTask();
  };

  const handleClose = () => {
    setDescription("");
    setType("");
    setFormData({ interaction_type: "" });
    setAddactivityerror("");
    onClose();
  };

  const handleChange = (value, name) => {
    setError({ ...error, [name]: "" });
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Modal title={"Complete Task"} desc={"Before completing this task, make a selection below."} show={showModal} onClose={handleClose}>
      <form className="py-3">
        <p className="head-4 dark-H mb-2">
          Would you like to add activity to{" "}
          <span className="green-H">
            {`${taskData?.contact?.first_name} ${taskData?.contact?.last_name}`}
          </span>
          's timeline?
        </p>

        <RadioGroup
          onChange={(value) => setType(value)}
          value={type}
        >
          <Stack direction="column" gap={2}>
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </Stack>
        </RadioGroup>

        {type === "yes" && (
          <>
            <div className="mt-6">
              <p className="head-4 dark-H">Add an activity to the contact timeline.</p>
              <p className="body-S dark-M mt-2">Interaction Type</p>
              
              <div className="mt-5 md:mt-0">
              <div ref={dropdownRef} className="custom-dropdown mt-2">
               <div
                 role="button"
                 className="select-header-input capitalize light-bg-L body-N dark-M flex justify-between items-center"
                 onClick={() => setIsOpen(!isOpen)}
               >
                 {formData?.interaction_type && activityOptions.some(el => el.value === formData.interaction_type)
                   ? activityOptions.find((el) => el.value === formData.interaction_type)?.label
                   : "Select"} {/* Ensures placeholder appears */}
                 <ArrowDown />
               </div>

               {isOpen && (
                 <div className="dropdown-list-container light-bg-L dark-M body-N shadow rounded-box w-52">
                   <ul className="dropdown-list">
                     {activityOptions.map((el, i) => (
                       <li
                         key={i}
                         role="button"
                         onClick={() => {
                           handleChange(el.value, "interaction_type");
                           setIsOpen(false);
                         }}
                         className={`${formData?.interaction_type === el.value ? "active" : ""}`}
                       >
                         {el.label}
                       </li>
                     ))}
                   </ul>
                 </div>
               )}
             </div>



                {addactivityerror?.interaction_type && <p className="body-S red-D">{addactivityerror?.interaction_type}</p>}
                {error?.interaction_type && <p className="body-S red-D">{error?.interaction_type}</p>}
              </div>
            </div>

            <div className="flex-1 mt-6">
              <p className="head-4 dark-H">
                Description <span className="body-S dar-M">(optional)</span>
              </p>
              <textarea
                rows={4}
                placeholder="Enter description here..."
                className="mt-2 w-full body-N"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </>
        )}

        <div className="mt-6">
          <button type="button" onClick={handleSubmit} className="save-button light-L head-5 green-bg-H">
            Complete
          </button>
          <button type="button" onClick={handleClose} className="green-H ml-5">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CompleteTaskModal;
