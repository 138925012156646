import React from "react";

const LeadDataContainer = ({ text, number, className, onClick }) => {
  return (
    <div 
      className={`flex flex-col text-center h-[8rem] w-full bg-white cursor-pointer  p-4 mb-3 ${className}`}
      onClick={onClick}
      style={{ minWidth: "150px" }} 
    >
      <p className="head-2 dark-H  flex items-center justify-center pb-2 mt-4">{number}</p>
      <p className="body-S dark-M">{text}</p>
    </div>
  );
};

export default LeadDataContainer;
