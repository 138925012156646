import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import AddOfferModal from "./AddOfferModal";
import Loader from "../../components/Loader";
import EditOfferModal from "./EditOfferModal";
import Plus from "../../assets/svgs/Plus.svg";
import { BASE_URL } from "../../utils/Element";
import BaseLayout from "../../layouts/BaseLayout";
import OfferFilter from "./components/OfferFilter";
import UpArrow from "../../assets/svgs/up-arrow.svg";
import DownArrow from "../../assets/svgs/down-arrow.svg";
import contact from "../../assets/svgs/account.svg";
import ActionsMenu from "../../components/ActionsMenu";
import swap from "../../assets/svgs/swap-vertical.svg";
import sortAsce from "../../assets/svgs/sort-ascending.svg";
import sortDesc from "../../assets/svgs/sort-descending.svg";
import { initialOfferFilterData } from "../../utils/initialData";
import ContactPagination from "../../components/Pagination/ContactPagination";
import DeleteConfirmationModal from "../../components/ConfirmationModals/DeleteConfirmationModal";
import ContractModal from "../../components/ConfirmationModals/ContractModal";
import LeadDataContainer from "../Leads/components/LeadDataContainer"
import CircleChart from "../../components/Charts/CircleChart"
import LeadDataContainer2 from "../Leads/components/LeadDataContainer2"
import BarData from "../../components/Charts/BarData";


const Offers = () => {
  const [config] = useAuth();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [leadId, setleadId] = useState("");
  const [loading, setLoading] = useState(true);
  const [offersData, setOffersData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
   const [activeTab, setActiveTab] = useState(0)
  const [itemPerPage, setItemPerPage] = useState(50);
  const [sortBy, setSortBy] = useState("id");
  const [sortDirection, setSortDirection] = useState("asc");
  const [expandedRows, setExpandedRows] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showContractModal, setShowContractModal] = useState(false);
  const isSidebarCollapsed = useSelector((state) => state.isSidebarCollapsed);
  const [paginationData, setPaginationData] = useState({ totalItems: 0, from: 0, to: 0, totalPages: 0 });
  const [filterData, setFilterData] = useState(() => {
    const localFilterData = localStorage.getItem("filterData");
    return localFilterData ? JSON.parse(localFilterData) : initialOfferFilterData;
  });

  const handleNext = () => {
    if (currentPage !== paginationData.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const buildQueryParams = (filters) => {
    let params = `page=${currentPage}&per_page=${itemPerPage}&search=${search}`;

    const { associate, contact, property_name, property_address, created_at, created_end_date, created_start_date, city, state, zip_code, offer_from, deal_type, offer_type, noi, offer_cap_rate, offer_price, min_asking_cap_rate, max_asking_cap_rate, min_asking_price, max_asking_price, percent_of_asking_price } = filters;

    if (associate) {
      params += `&broker=${encodeURIComponent(associate)}`;
    }
    if (contact?.id !== "") {
      params += `&contact=${encodeURIComponent(contact?.id)}`;
    }
    if (property_name) {
      params += `&property_name=${encodeURIComponent(property_name)}`;
    }
    if (property_address) {
      params += `&property_address=${encodeURIComponent(property_address)}`;
    }
    if (created_at) {
      params += `&created_at=${encodeURIComponent(created_at)}`;
    }
    if (created_start_date) {
      params += `&created_start_date=${encodeURIComponent(created_start_date)}`;
    }
    if (created_end_date) {
      params += `&created_end_date=${encodeURIComponent(created_end_date)}`;
    }
    if (city) {
      params += `&city=${encodeURIComponent(city)}`;
    }
    if (state) {
      params += `&state=${encodeURIComponent(state)}`;
    }
    if (zip_code) {
      params += `&zip_code=${encodeURIComponent(zip_code)}`;
    }
    if (offer_from) {
      params += `&offer_from=${encodeURIComponent(offer_from)}`;
    }
    if (deal_type) {
      params += `&deal_type=${encodeURIComponent(deal_type === "acquisition" ? 1 : 0)}`;
    }
    if (offer_type) {
      params += `&offer_type=${encodeURIComponent(offer_type)}`;
    }
    if (noi) {
      params += `&noi=${encodeURIComponent(noi)}`;
    }
    if (offer_cap_rate) {
      params += `&offer_cap_rate=${encodeURIComponent(offer_cap_rate)}`;
    }
    if (offer_price) {
      params += `&offer_price=${encodeURIComponent(offer_price)}`;
    }
    if (min_asking_cap_rate) {
      params += `&min_asking_cap_rate=${encodeURIComponent(min_asking_cap_rate)}`;
    }
    if (max_asking_cap_rate) {
      params += `&max_asking_cap_rate=${encodeURIComponent(max_asking_cap_rate)}`;
    }
    if (min_asking_price) {
      params += `&min_asking_price=${encodeURIComponent(min_asking_price)}`;
    }
    if (max_asking_price) {
      params += `&max_asking_price=${encodeURIComponent(max_asking_price)}`;
    }
    if (percent_of_asking_price) {
      params += `&percent_of_asking_price=${encodeURIComponent(percent_of_asking_price)}`;
    }
    if (sortDirection && sortBy !== "id") {
      params += `&sort_direction=${sortDirection}`;
    }
    if (sortBy !== "id") {
      params += `&sort_by=${sortBy}`;
    }

    return params;
  };

  const fetchOffers = (filters) => {
    const queryParams = buildQueryParams(filters);
    axios
      .get(`${BASE_URL}/offer-listing?status=${activeTab}${queryParams}`, config)
      .then((res) => {
        const value = res?.data?.Offerlist;
        console.log(">>>",value);
        
        setOffersData(value?.data || []);
        setPaginationData({
          totalItems: value?.total ?? 0,
          from: value?.from ?? 0,
          to: value?.to ?? 0,
          totalPages: value?.last_page ?? 0,
        });
    
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchOffers(filterData);
  }, [sortDirection, sortBy]);

  useEffect(() => {
    onSuccess();
  }, [search, currentPage, itemPerPage , activeTab]);

  const onSuccess = (filters) => {
    setLoading(true);
    if (filters) {
      fetchOffers(filters);
    } else {
      fetchOffers(filterData);
    }
  };

  const handleDelete = () => {
    setDisableDelete(true);
    axios
      .delete(`${BASE_URL}/delete-offer/${selectedId}`, config)
      .then((res) => {
        setSelectedId("");
        onSuccess();
        setShowDeleteModal(false);
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setDisableDelete(false));
  };

  const handleUnderContract = () => {
    axios
      .delete(`${BASE_URL}/mark-as-under-contract/${selectedId}`, config)
      .then((res) => {
        setSelectedId("");
        onSuccess();
        setShowContractModal(false);
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const toggleRowDetails = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle only the clicked row
    }));
  };

  const data = [
    { value: "22", name: "< 90%15.4%", color: "#2D5B30" },
    { value: "20", name: "> 96%23.1%", color: "grey" },
    { value: "19", name: "91%-95%", color: "black" },
  ];
  
  const BarGraphData = [
    { name: "<$1M", count: 10 },
    { name: "$1M-$3M", count: 15 },
    { name: ">$3M", count: 25 }
  ]


  return (
    <BaseLayout>
      <div className="contact-header">
        <p className="head-1 dark-H">Offers</p>
        <div className="md:flex block gap-4 items-center">
          <div className="flex gap-4 justify-between items-center">
            <OfferFilter
              filterData={filterData}
              onSetFilterData={(value) => {
                setFilterData(value);
              }}
              onCallApiAgain={(filters) => onSuccess(filters)}
            />
            <div className="search-box contacts">
              <input
                type="text"
                className="body-S"
                placeholder="Search name, address, city, state..."
                value={search}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSearch(newValue.startsWith(" ") ? newValue.trimStart() : newValue);
                }}
                style={{ border: "1px solid #D8D8D8", marginTop: "0px" }}
              />
            </div>
            <button
              className="add-contact-button green-bg-H light-L body-S"
              onClick={() => {
                setSelectedId("");
                setShowAddModal(true);
              }}
            >
              <img className="mr-2" src={Plus} alt="plus" /> Add
            </button>
          </div>
        </div>
      </div>


      <div className="task-tabs mx-1 mt-2 flex justify-between body-L dark-M mb-4">
        <div className="flex gap-4">
          <p
            role="button"
            className={`${activeTab === 0 ? "head-4 dark-H active" : ""} pb-3`}
            onClick={() => {
              setActiveTab(0)
              setCurrentPage(1)
            }}
          >
            Team Dashboard
          
          </p>
          <p
            role="button"
            className={`${activeTab === 1 ? "head-4 dark-H active" : ""} pb-3`}
            onClick={() => {
              setActiveTab(1)
              setCurrentPage(1)
            }}
          >
           My Dashboard
          </p>
          <p
            role="button"
            className={`${activeTab === 2 ? "head-4 dark-H active" : ""} pb-3`}
            onClick={() => {
              setActiveTab(2)
              setCurrentPage(1)
            }}
          >
             My Offers
          </p>
          <p
            role="button"
            className={`${activeTab === 3 ? "head-4 dark-H active" : ""} pb-3`}
            onClick={() => {
              setActiveTab(3)
              setCurrentPage(1)
            }}
          >
            Team  Offers
          </p>
        </div>
      </div>

      {(activeTab === 2 || activeTab === 3) && (
      <div className="light-bg-L pb-3 table-container">
        <div className={`${isSidebarCollapsed ? "collapsed-width" : "width"} table-info light-bg-L`}>
          {loading ? (
            <Loader />
          ) : offersData?.length === 0 ? (
            <p className="body-N text-center mt-6 p-10">No Offers Available</p>
          ) : (
            <table className="contact-table light-bg-L">
              <thead>
                <tr className="uppercase body-N dark-M">
                 <th>
                 <p>Details</p>
                 </th>

                  <th>
                    <div className="table-header">
                      Property Name
                      <img
                        role="button"
                        src={sortBy !== "property_name" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("property_name");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      City
                      <img
                        role="button"
                        src={sortBy !== "city" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("city");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      State
                      <img
                        role="button"
                        src={sortBy !== "state" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("state");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Offer Date
                      <img
                        role="button"
                        src={sortBy !== "created_at" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("created_at");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Initial or Counter
                      <img
                        role="button"
                        src={sortBy !== "type" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("type");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Noi
                      <img
                        role="button"
                        src={sortBy !== "anual_rent" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("anual_rent");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Offer Cap Rate
                      <img
                        role="button"
                        src={sortBy !== "offer_cap_rate" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("offer_cap_rate");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Offer Price
                      <img
                        role="button"
                        src={sortBy !== "offer_price" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("offer_price");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      % Asking Price
                      <img
                        role="button"
                        src={sortBy !== "percent_of_asking_price" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("percent_of_asking_price");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">More</div>
                  </th>
                </tr>
              </thead>

              <tbody>
               {offersData?.map((el, idx) => (
                 <React.Fragment key={idx}>
                   <tr className="dark-H body-N">
                     <td>
                       <img 
                         role="button"
                         src={expandedRows[el.id] ? UpArrow : DownArrow}
                         alt="icon"
                         className="sidebar-icons"
                         onClick={() => toggleRowDetails(el.id)}
                       />
                     </td>
                     <td className="head-5 capitalize">
                       <Link to={`/property/${el?.property_id}`}
                         onClick={(e) => {
                           e.preventDefault();
                           navigate(`/property/${el?.property_id}`); 
                         }}>
                         {el?.property?.property_name}
                       </Link>
                     </td>
                     <td>{el?.property?.city}</td>
                     <td>{el?.property?.state}</td>
                     <td>{moment(el?.created_at).tz('America/New_York').format("MM/DD/YY")}</td>
                     <td className="flex items-center gap-2">
                       <p className="tags green-H body-S capitalize">{el?.type} Counter</p>
                     </td>
                     <td>{el?.property?.anual_rent ? `$ ${el?.property?.anual_rent?.toLocaleString()}` : ""}</td>
                     <td className="text-center">{el?.offer_cap_rate ? `${el?.offer_cap_rate?.toLocaleString()} %` : ""}</td>
                     <td>{el?.offer_price ? `$ ${el?.offer_price?.toLocaleString()}` : ""}</td>
                     <td className="text-center">
                       {el?.percent_of_asking_price
                         ? `${Number(el?.percent_of_asking_price)?.toLocaleString(undefined, { maximumFractionDigits: 2 })} %`
                         : ""}
                     </td>
                     <td className="flex gap-3 items-center w-full justify-center">
                       <ActionsMenu
                         from="Offers"
                         handleEdit={() => {
                           setShowEditModal(true);
                           setSelectedId(el?.id);
                           setleadId(el?.lead_id);
                         }}
                         handleDelete={() => {
                           setSelectedId(el?.id);
                           setShowDeleteModal(true);
                         }}
                         showOtherOption={true}
                         otherOptionTitle={"New Counter Offer"}
                         handleOtherOption={() => {
                           setShowAddModal(true);
                           setSelectedId(el?.id);
                         }}
                         otherUnderContractTitle={<> Convert to <br /> Under Contract</>}
                         handleUnderContract={() => {
                           setSelectedId(el?.id);
                           setShowContractModal(true);
                         }}
                         otherConverttoExecutedLOI={<> Convert to <br />Executed LOI</>}
                         handleConverttoExecutedLOI={() => {
                           setSelectedId(el?.id);
                         }}
                       />
                     </td>
                   </tr>
             
                   {expandedRows[el.id] && (
                      <tr>
                        <td colSpan="11">
                        <div className="expanded-row-content p-4 bg-[rgb(244,244,244)] rounded-md">
                            <div className="">

                            <div className="flex items-center gap-5">
                           
                              <div>
                                <p className="dark-H head-4 mb-2">Seller Broker</p>
                                <p className="body-N  dark-H">
                                  {el?.seller_broker?.first_name} {el?.seller_broker?.last_name || "N/A"}
                                </p>
                              </div>
                    
                              <div>
                                <p className="dark-H head-4 mb-2">Seller BDS</p>
                                <p className="body-N  dark-H">
                                  {el?.seller_bds?.first_name} {el?.seller_bds?.last_name || "N/A"}
                                </p>
                              </div>
                    
                              <div>
                                <p className="dark-H head-4 mb-2">Buyer Broker</p>
                                <p className="body-N  dark-H">
                                  {el?.buyer_broker?.first_name} {el?.buyer_broker?.last_name || "N/A"}
                                </p>
                              </div>
                  
                              <div>
                                <p className="dark-H head-4 mb-2">Buyer BDS</p>
                                <p className="body-N  dark-H">
                                  {el?.buyer_bds?.first_name} {el?.buyer_bds?.last_name || "N/A"}
                                </p>
                              </div>
                               
                            </div>

                            <div className="flex items-center gap-5 mt-4">
                              
                            <div>
                                <p className="dark-H head-4">Acquistion</p>
                                <Link 
                                  to={`/contact/${el?.buyer_contact_id}`}
                                  className="body-N  dark-H "
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate(`/contact/${el?.buyer_contact_id}`);
                                  }}
                                >
                                  {el?.acquistion_name || "N/A"}
                                </Link>
                              </div>

                              <div>
                                <p className="dark-H head-4">Disposition</p>
                                <Link 
                                  to={`/contact/${el?.seller_contact_id}`}
                                  className="body-N  dark-H "
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate(`/contact/${el?.seller_contact_id}`);
                                  }}
                                >
                                  {el?.disposition_name || "N/A"}
                                </Link>
                              </div>

                              <div>
                                <p className="dark-H head-4 mb-2">Asking Cap Rate</p>
                                <p className="body-N  dark-H ">
                                  {el?.property?.asking_cap_rate ? `${el.property.asking_cap_rate.toLocaleString()} %` : "N/A"}
                                </p>
                              </div>

                              <div>
                                <p className="dark-H head-4 mb-2">Asking Price</p>
                                <p className="body-N  dark-H ">
                                  {el?.property?.asking_price ? `$ ${el.property.asking_price.toLocaleString()}` : "N/A"}
                                </p>
                              </div>

                             </div>
                              
                           </div>
                          </div>
                        </td>
                      </tr>
                    )}
                 </React.Fragment>
               ))}
              </tbody>
         </table>
          )}
        </div>

        <ContactPagination
          activeTab={activeTab}
          paginationData={paginationData}
          handleNext={handleNext}
          handlePrev={handlePrev}
          onSuccess={onSuccess}
          itemPerPage={itemPerPage}
          handleItemPerPageSelect={(value) => {
            setItemPerPage(value);
            setCurrentPage(1);
          }}
        />
      </div>
      )}

       {(activeTab === 0 || activeTab === 1) && (
        <>
         <div className="">
        <div className="w-auto">
        <div className="flex items-center gap-3">
         <LeadDataContainer text="NEW INITIAL OFFERS THIS QUARTER" number="3.0" className="flex-1" />
         <LeadDataContainer text="TOTAL INITIAL OFFERS" number="32" className="flex-1" />
         <LeadDataContainer text="BUYER INITIAL OFFERS" number="3" className="flex-1" />
         <LeadDataContainer text="SELLER INITIAL OFFERS" number="30" className="flex-1" />
        
       </div>

           <div className="flex gap-3 ">
          
           <div className="w-[25%]">
            <LeadDataContainer text="BUYER LEAD TO BUYER INITIAL OFFER CONVERSION RATE" number="3" className="flex-1 " />
            <LeadDataContainer text="SELLER LEAD TO SELLER INITIAL OFFER CONVERSION RATE" number="30" className="flex-1 " />
            </div>


            <div className="flex gap-3 items-center w-[75%]">
            <CircleChart
              Title="% OF ASKING PRICE"
              data={data} />

              <BarData 
              Title = "OFFER PRICE POINT"
              BarGraphData={BarGraphData}
              />
            </div>
         </div>

        </div>

        </div>
        
        </>
       )}

      <AddOfferModal
        showModal={showAddModal}
        onClose={() => setShowAddModal(false)}
        onCallApi={() => {
          setSelectedId("");
          onSuccess();
        }}
        id={selectedId}
      />

      <EditOfferModal
        showModal={showEditModal}
        onClose={() => setShowEditModal(false)}
        id={selectedId}
        onCallApi={() => {
          setSelectedId("");
          onSuccess();
        }}
      />

      <ContractModal
        showModal={showContractModal}
        onClose={() => setShowContractModal(false)}
        handleUnderContract={handleUnderContract}
      />

      <DeleteConfirmationModal 
      showModal={showDeleteModal} 
      disable={disableDelete} 
      onClose={() => setShowDeleteModal(false)} 
      handleDelete={handleDelete} />

    </BaseLayout>
  );
};

export default Offers;
