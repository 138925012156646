import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import EditBuyersModal from "../EditBuyersModal";
import phone from "../../../assets/svgs/call.svg";
import { BASE_URL } from "../../../utils/Element";
import BuyerDetailModal from "../BuyerDetailModal";
import ActionsMenu from "../../../components/ActionsMenu";
import DeleteConfirmationModal from "../../../components/ConfirmationModals/DeleteConfirmationModal";

const BuyersDetailsHeader = ({ buyerData, onCallApi }) => {
  const { id } = useParams();
  const [config] = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDelete = () => {
    setDisableDelete(true);
    axios
      .delete(`${BASE_URL}/delete-acquisition/${id}`, config)
      .then((res) => {
        navigate("/buyers");
        setShowDeleteModal(false);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setDisableDelete(false));
  };

  return (
    <div>
      <div className="md:flex items-start justify-between">
        <div>
          <p
            className="head-2 green-H underline capitalize"
            role="button"
          >
            <a
              href={buyerData?.contact_id ? `/contact/${buyerData?.contact_id}` : `/company/${buyerData?.company_id}`}
              rel="noopener noreferrer"
              onClick={(e) => {
                if (buyerData?.contact_id) {
                  navigate(`/contact/${buyerData?.contact_id}`);
                } else {
                  navigate(`/company/${buyerData?.company_id}`);
                }
              }}
            >
            {buyerData?.contact?.name}
            </a>
          </p>
          <p className="body-N dark-M">Last contact on {buyerData?.contact?.get_latest_activity ? ` (${moment(buyerData?.contact?.get_latest_activity.date_connected).tz('America/New_York').fromNow()})` : ''}</p>

        </div>

        <div className="flex items-center gap-3 mt-5 md:mt-0">
          <ActionsMenu
            handleEdit={() => {
              setShowModal(true);
            }}
            handleDelete={() => {
              setShowDeleteModal(true);
            }}
            // showOtherOption={true}
            // otherOptionTitle={"View"}
            // handleOtherOption={() => {
            //   setShowDetailModal(true);
            // }}
          />
        </div>
      </div>

      <div className="mt-6 md:flex justify-between body-N">
        <div>
          <p className="dark-M">Last Update</p>
          <p className="dark-H">{moment(buyerData?.criteria_update_date).tz('America/New_York').format("MM/DD/YYYY")}</p>
        </div>
    </div>

     
      <BuyerDetailModal showModal={showDetailModal} onClose={() => setShowDetailModal(false)} id={id} />

      <EditBuyersModal showModal={showModal} onClose={() => setShowModal(false)} onCallApi={onCallApi} id={id} />

      <DeleteConfirmationModal disable={disableDelete} showModal={showDeleteModal} onClose={() => setShowDeleteModal(false)} handleDelete={handleDelete} />
    </div>
  );
};

export default BuyersDetailsHeader;
