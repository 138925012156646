import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import "./Companies.css";
import useAuth from "../../hooks/useAuth";
import Loader from "../../components/Loader";
import Plus from "../../assets/svgs/Plus.svg";
import { BASE_URL } from "../../utils/Element";
import AddCompanyModal from "./AddCompanyModal";
import BaseLayout from "../../layouts/BaseLayout";
import { formatPhoneNumber } from "../../utils/utils";
import swap from "../../assets/svgs/swap-vertical.svg";
import CompanyFilter from "./components/CompanyFilter";
import ImportExport from "../../components/ImportExport";
import sortAsce from "../../assets/svgs/sort-ascending.svg";
import sortDesc from "../../assets/svgs/sort-descending.svg";
import { initialCompanyFilterData } from "../../utils/initialData";
import ContactPagination from "../../components/Pagination/ContactPagination";

const Companies = () => {
  const [config] = useAuth();
  const navigate = useNavigate();
  const [tagss, setTagss] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(50);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);
  const [sortBy, setSortBy] = useState("updated_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const isSidebarCollapsed = useSelector((state) => state.isSidebarCollapsed);
  const [paginationData, setPaginationData] = useState({ totalItems: 0, from: 0, to: 0, totalPages: 0 });
  const [filterData, setFilterData] = useState(() => {
    const localFilterData = localStorage.getItem("filterData");
    return localFilterData ? JSON.parse(localFilterData) : initialCompanyFilterData;
  });
  const queryParams = new URLSearchParams(location.search);
  const [search, setSearch] = useState(queryParams.get('search') ?? '');

  const companyAllIds = companiesData?.map((el) => el.id);

  const buildQueryParams = (filters) => {
    let params = `page=${currentPage}&per_page=${itemPerPage}`;

    if (search) {
        const encodedSearch = encodeURIComponent(search);
        params += `&search=${encodedSearch}`;
    }
    const { company_name, property, primaryphone, owner_without_tags ,owner_tags , streetaddress, city, state, zip, website, count, start_count, end_count, taxrecord, taxrecordsentdate, taxrecordsentSdate, taxrecordsentEdate, tags, last_contact, lastContactSentDate, lastContactSentEdate, lastContactSentSdate, date_category, longer_than_day, within_last_day } = filters;

    if (company_name !== undefined) {
      params += `&company_name=${encodeURIComponent(company_name)}`;
    }
    if (property !== "" && property !== undefined) {
      params += `&property=${encodeURIComponent(property)}`;
    }
    if (primaryphone !== undefined) {
      params += `&phone=${encodeURIComponent(primaryphone)}`;
    }
    if (streetaddress !== undefined) {
      params += `&street_address=${encodeURIComponent(streetaddress)}`;
    }
    if (city !== undefined) {
      params += `&city=${encodeURIComponent(city)}`;
    }
    if (state !== undefined) {
      params += `&state=${encodeURIComponent(state)}`;
    }
    if (zip !== undefined) {
      params += `&zip_code=${encodeURIComponent(zip)}`;
    }
    if (website !== undefined) {
      params += `&website=${encodeURIComponent(website)}`;
    }
    if (taxrecord !== undefined) {
      params += `&tax_record_sent=${encodeURIComponent(taxrecord)}`;
    }
    if (taxrecordsentdate !== undefined) {
      params += `&tax_record_sent_date=${encodeURIComponent(taxrecordsentdate)}`;
    }
    if (taxrecordsentSdate !== undefined) {
      params += `&tax_record_start_date=${encodeURIComponent(taxrecordsentSdate)}`;
    }
    if (taxrecordsentEdate !== undefined) {
      params += `&tax_record_end_date=${encodeURIComponent(taxrecordsentEdate)}`;
    }
    if (tags.length > 0) {
      params += `&tags=${tags.join(",")}`;
    }
    if (last_contact !== undefined ) {
      params += `&last_contact=${encodeURIComponent(last_contact)}`;
    }
    if (lastContactSentDate !== undefined) {
      params += `&last_update_date=${encodeURIComponent(lastContactSentDate)}`;
    }
    if (lastContactSentSdate !== undefined) {
      params += `&last_update_start_date=${encodeURIComponent(lastContactSentSdate)}`;
    }
    if (lastContactSentEdate !== undefined) {
      params += `&last_update_end_date=${encodeURIComponent(lastContactSentEdate)}`;
    }
    if (date_category !== undefined) {
      params += `&date_category=${encodeURIComponent(date_category)}`;
    }
    if (within_last_day) {
      params += `&within_last_day=${encodeURIComponent(within_last_day)}`;
    }
    if (longer_than_day) {
      params += `&longer_than_day=${encodeURIComponent(longer_than_day)}`;
    }
    if (count !== undefined) {
      params += `&count=${encodeURIComponent(count)}`;
    }
    if (start_count !== undefined) {
      params += `&start_count=${encodeURIComponent(start_count)}`;
    }
    if (end_count !== undefined) {
      params += `&end_count=${encodeURIComponent(end_count)}`;
    }
    if (sortDirection !== undefined && sortBy !== "id") {
      params += `&sort_direction=${sortDirection}`;
    }
    if (sortBy !== "id" && sortBy !== undefined) {
      params += `&sort_by=${sortBy}`;
    }
    if (owner_tags?.length > 0) {
      params += `&owner_tags=${encodeURIComponent(owner_tags)}`;
    }
    if (owner_without_tags?.length > 0) {
      params += `&owner_without_tags=${encodeURIComponent(owner_without_tags)}`;
    }
    
    localStorage.setItem("queryParams", params);

    return params;
  };

  const handleSelectCheck = (item) => {
    const isSelected = selectedItem.some((el) => el.id === item.id);
  
    if (isSelected) {
      setSelectedItem(selectedItem.filter((el) => el.id !== item.id));
    } else {
      setSelectedItem([...selectedItem, item]);
    }
  };
  

  const fetchCompanies = (filters) => {
    const queryParams = buildQueryParams(filters);
    axios
      .get(`${BASE_URL}/company-list?${queryParams}`, config)
      .then((res) => {
        const value = res?.data?.data;
        setCompaniesData(value?.companies?.data || []);
        setPaginationData({
          totalItems: value?.companies?.total ?? 0,
          from: value?.companies?.from ?? 0,
          to: value?.companies?.to ?? 0,
          totalPages: value?.companies?.last_page ?? 0,
        });
        setSelectedItem([]);
        setIsSelectAll(false);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCompanies(filterData);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    onSuccess();
  }, [currentPage, itemPerPage, search, sortBy, sortDirection]);

  const handleNext = () => {
    if (currentPage !== paginationData.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const onSuccess = (filters) => {
    setLoading(true);
    if (filters) {
      fetchCompanies(filters);
    } else {
      fetchCompanies(filterData);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams) {
      queryParams.set('search', search);
    } else {
      queryParams.delete('search'); 
    }
    navigate({ search: queryParams.toString() }, { replace: true });
  }, [search, navigate, location.search]);

  const selectedCompanies = selectedItem.length !== 0 ? companiesData?.filter((el) => selectedItem?.includes(el?.id)) : [];

   

  return (
    <BaseLayout>
      <div className="contact-header">
        <p className="head-1 dark-H">
          Companies
          {selectedItem?.length > 0 && (
            <span className="body-L ml-2">
              ({selectedItem?.length} <span className="dark-H">{selectedItem?.length === 1 ? "item" : "items"} selected</span>)
            </span>
          )}
        </p>
        <div className="md:flex gap-4 items-center">
          <div className="flex gap-4 items-center">
            <CompanyFilter
              filterData={filterData}
              onSetFilterData={(value) => {
                setFilterData(value);
              }}
              onCallApiAgain={(filters) => onSuccess(filters)}
            />

            <ImportExport
              from="company"
              onCallApi={() => {
                onSuccess();
                setSelectedItem([]);
              }}
              selectedData={selectedCompanies}
            />
          </div>

          <div className="flex gap-4 justify-between items-center">
            <div className="search-box contacts">
              <input
                type="text"
                className="body-S"
                placeholder="Search name and website link..."
                value={queryParams.get('search')}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSearch(newValue.startsWith(" ") ? newValue.trimStart() : newValue);
                }}
                style={{ border: "1px solid #D8D8D8", marginTop: "0px" }}
              />
            </div>
            <button
              className="add-contact-button green-bg-H light-L body-S"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <img className="mr-2 sidebar-icons" src={Plus} alt="plus" /> Add
            </button>
          </div>
        </div>
      </div>

      <div className="light-bg-L pb-3 table-container">
        <div className={`${isSidebarCollapsed ? "collapsed-width" : "width"} table-info light-bg-L`}>
          {loading ? (
            <Loader />
          ) : companiesData.length === 0 ? (
            <p className="body-N text-center mt-6 p-10">No Companies Found</p>
          ) : (
            <table className="contact-table two-rows-static light-bg-L">
              <thead>
                <tr className="uppercase body-N dark-M">
                <th>
                 <label className="container">
                      <input
                        type="checkbox"
                        checked={selectedItem.length > 0 && selectedItem.length === companiesData.length}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setIsSelectAll(isChecked);
                  
                          if (isChecked) {
                            setSelectedItem([...companiesData]);
                          } else {
                            setSelectedItem([]);
                          }
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
               </th>

             
                  <th>
                    <div className="table-header green-H">
                      Company Name
                      <img
                        role="button"
                        src={sortBy !== "company_name" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("company_name");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Last Contacted
                      <img
                        role="button"
                        src={sortBy !== "updated_at" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        alt="icon"
                        onClick={() => {
                          setSortBy("updated_at");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header ">
                      Website Url
                      <img
                        role="button"
                        src={sortBy !== "website_link" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        alt="icon"
                        onClick={() => {
                          setSortBy("website_link");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header ">
                       street
                      <img
                        role="button"
                        src={sortBy !== "street" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        alt="icon"
                        onClick={() => {
                          setSortBy("street");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                      />
                    </div>
                  </th>

                  <th>
                    <div className="table-header ">
                    city
                      <img
                        role="button"
                        src={sortBy !== "city" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        alt="icon"
                        onClick={() => {
                          setSortBy("city");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                      />
                    </div>
                  </th>

                  <th>
                    <div className="table-header ">
                      state
                      <img
                        role="button"
                        src={sortBy !== "state" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        alt="icon"
                        onClick={() => {
                          setSortBy("state");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                      />
                    </div>
                  </th>
                  
                  <th>
                    <div className="table-header">
                      Company Phone
                      <img
                        role="button"
                        src={sortBy !== "phone" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        alt="icon"
                        onClick={() => {
                          setSortBy("phone");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                      />
                    </div>
                  </th>


                  <th>
                    <div className="table-header">
                      Tags
                      <img
                        role="button"
                        src={sortBy !== "communication" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        alt="icon"
                        onClick={() => {
                          setSortBy("communication");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                      />
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {companiesData.flatMap((el, idx) => (
                  <tr key={idx} className="dark-H body-N">
                    <td className="">
                        <label className="container">
                          <input
                            type="checkbox"
                            checked={selectedItem.some((item) => item.id === el.id)}
                            onChange={() => handleSelectCheck(el)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </td>

                    <td className="head-5 normal-case">
                      <Link
                        to={`/company/${el.id}?search=${search}`}
                        rel="noopener noreferrer"
                        onClick={(e) => {
                          e.preventDefault(); 
                          navigate(`/company/${el.id}?search=${search}`); 
                        }}
                      >
                        {el.company_name}
                      </Link>
                    </td>


                    <td>
                      {el?.latest_activity_including_contacts?.date ? moment(el?.latest_activity_including_contacts?.date).tz('America/New_York').format("MM/DD/YY") : ''}  <span className="dark-M body-S">{el?.latest_activity_including_contacts?.date_connected ? ` (${moment(el.latest_activity_including_contacts.date_connected).fromNow()})`: ''}</span>
                    </td>
                    <td
                      role={el.website_link ? "button" : ""}
                      className="green-H"
                      onClick={() => {
                        if (el.website_link) {
                          const websiteLink = el?.website_link;
                          const validWebsiteLink = websiteLink && (websiteLink.startsWith('http://') || websiteLink.startsWith('https://')) 
                          ? websiteLink 
                          : `http://${websiteLink}`;
                          window.open(validWebsiteLink, "_blank");
                        }
                      }}
                    >
                      {el.website_link?.slice(0, 35)}
                    </td>
                    <td >
                      {el.address && el.address.length > 0 && el.address[0].street}
                    </td>
                     
                    <td>
                    {el.address && el.address.length > 0 && el.address[0].city}
                   </td>

                    <td >
                      {el.address && el.address.length > 0 && el.address[0].state}
                    </td>

                     <td>
                      {formatPhoneNumber(el.phone?.[0]?.phone_number)}
                    </td>
                    <td className="flex items-center gap-2">
                      {el?.communication?.map((tag, i) =>
                        tag ? (
                          <p key={i} className="tags green-H body-S">
                            {tag}
                          </p>
                        ) : null
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {companiesData.length !== 0 && (
          <ContactPagination
            from="company"
            selectedItem={selectedItem}
            paginationData={paginationData}
            handleNext={handleNext}
            handlePrev={handlePrev}
            tags={tagss}
            setTags={setTagss}
            onSuccess={onSuccess}
            itemPerPage={itemPerPage}
            handleItemPerPageSelect={(value) => {
              setItemPerPage(value);
              setCurrentPage(1);
            }}
          />
        )}
      </div>

      <AddCompanyModal
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        onSuccess={onSuccess}
      />
    </BaseLayout>
  );
};

export default Companies;
