import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import { BASE_URL } from "../../../utils/Element";
import AddTaskData from "../components/AddTaskData";
import Modal from "../../../components/Modal/Modal";
import { NotificationManager } from "react-notifications";

const initialData = {
  task_name: "",
  description: "",
  hyperlink: "",
  task_type: "call",
  priority: "none",
  due_date: "",
  repeat: "",
  repeat_date: "",
  bds: "",
  contact_id: '',
  company_id:''
};

const AddTaskModal = ({ showModal, onClose, onSuccess, from, data }) => {
  const { id } = useParams();
  const [config] = useAuth();
  const [error, setError] = useState({});
  const [taskData, setTaskData] = useState(initialData);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  

  useEffect(() => {
    if (showModal) {
      if (from === "contact" && data?.id) {
        setSelectedContacts([{ id: data.id, name: `${data?.first_name} ${data?.last_name}` }]);
      } else if (from === "contact") {
        setSelectedContacts([{ id: id }]);
      }
  
      if (from === "company" && data?.id) {
        setSelectedCompanies([{ id: data.id, name: data?.company_name }]);
      } else if (from === "company") {
        setSelectedCompanies([{ id: id }]);
      }
    }
  }, [showModal, from, data, id]);
  
  
  const handleCloseModal = () => {
    onClose();
    setTimeout(() => { 
      setSelectedContacts([]);
      setSelectedCompanies([]);
      setTaskData(initialData);
      setError({});
    }, 100);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const taskPayload = {
      task_name: taskData.task_name,
      company_id: selectedCompanies.length > 0 ? selectedCompanies.map((el) => el.id) : [],
      contact_id: selectedContacts.length > 0 ? selectedContacts.map((el) => el.id) : [],
      priority: taskData.priority,
      task_type: taskData.task_type,
      description: taskData.description,
      due_date: taskData.due_date,
      hyperlink: taskData.hyperlink,
      user_id: taskData.bds,
      repeat: taskData.repeat === "custom" ? taskData?.repeat_date : taskData.repeat,
    };

    axios
      .post(`${BASE_URL}/tasks`, taskPayload, config)
      .then((res) => {
        handleCloseModal();
        onSuccess();
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
          setTaskData(initialData);
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.errors || {});
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  const handleInputChange = (value, name) => {
    setTaskData({ ...taskData, [name]: value });
    setError((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSelectedContacts = (contacts) => {
    setSelectedContacts(contacts);
  };

  const handleSelectedCompany = (companies) => {
    setSelectedCompanies(companies);
  };
  return (
    <Modal title={"Create Task"} desc={"Enter information for the task."} show={showModal} onClose={handleCloseModal}>
      <form className="py-3" onSubmit={handleSubmit}>
        <AddTaskData from={from} type="add" data={data} error={error} taskData={taskData} selectedContacts={selectedContacts} selectedCompanies={selectedCompanies}  handleInputChange={handleInputChange} handleSelectedContacts={handleSelectedContacts} handleSelectedCompany={handleSelectedCompany} />

        <div className="mt-6">
          <button type="submit" className="save-button light-L head-5 green-bg-H">
            Create Task
          </button>
          <button type="button" onClick={handleCloseModal} className="green-H ml-5">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddTaskModal;
