import React, { useEffect, useState } from "react";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import { BASE_URL } from "../../../utils/Element";
import Modal from "../../../components/Modal/Modal";
import AddpipelineData from "../Component/AddPipelineData";
import { NotificationManager } from "react-notifications";

const initialData = {
associate_id:"",
contact_id:"",
transaction_type:"",
stage:"",
potential_gcl:"",
};


const AddPiplineModal = ({ showModal, onClose, onSuccess , onCallApi }) => {
  const [config] = useAuth();
  const [disable, setDisable] = useState(false);
  const [addpipelineData, setAddpipelineData] = useState(initialData);
  const [addpipelineError, setAddpipelineError] = useState({ pipelinename: "" });

  const handleClose = () => {
    setDisable(false);
    setAddpipelineData(initialData);
    onClose();
  };

 
  const handleSubmit = (e) => {
    e.preventDefault();
    setDisable(true);
    const dataToSend = {
      contact_id: addpipelineData.contact_id,
      associate_id: addpipelineData.associate_id,
      transaction_type: addpipelineData.transaction_type,
      stage: addpipelineData.stage,
      potential_gcl: addpipelineData.potential_gcl      ,
      };

    axios
      .post(`${BASE_URL}/add-pipeline`, dataToSend, config)
      .then((res) => {
        handleClose();
        onCallApi();
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
            console.error("API Error:", err.response.data.errors);
        } else {
            console.error("Unexpected Error:", err);
        }
         setDisable(false);
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  const handleChange = (value, name) => {
    setAddpipelineData((prevData) => ({ ...prevData, [name]: value }));
    if (name === "pipelineName") {
      setAddpipelineError({ ...addpipelineError, pipelinename: "" });
    }
  };

  useEffect(() => {
    if (addpipelineData.pipelineName) {
      setAddpipelineError({ pipelinename: "" });
    }
  }, [addpipelineData.pipelineName]);

  return (
    <Modal title={"Add pipeline"} desc={"Add the pipeline information."} show={showModal} onClose={handleClose}>
      <AddpipelineData
        addpipelineData={addpipelineData}
        onSetAddpipelineData={(value) => {
          setAddpipelineData(value);
        }}
        handleSubmit={handleSubmit}
        showModal={showModal}
        handleChange={handleChange}
        disable={disable}
        error={addpipelineError}
        onClose={handleClose}
      />
    </Modal>
  );
};

export default AddPiplineModal;
