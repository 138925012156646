import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from "recharts";

const CircleChart = ({ data = [], Title, Total ,TotalTitle }) => {
  const formattedData = data.map((item) => ({
    ...item,
    value: Number(item.value),
  }));

  return (
    <div className="light-bg-L h-full w-[50%] p-4">
      <div className="flex justify-between">
        <h2 className="head-2 dark-H">{Title}</h2>
      </div>

      <div className="flex justify-center relative">
        <div className="w-full md:w-3/4">
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={formattedData}
                dataKey="value"
                startAngle={70}
                endAngle={480}
                innerRadius={80}
                outerRadius={110}
                paddingAngle={0}
              >
                {formattedData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip formatter={(value) => `${value} Contacts`} />
            </PieChart>
          </ResponsiveContainer>
          <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
            <div className="text-center">
              <div className="body-N dark-M">{TotalTitle}</div>
              <div className="number-Text dark-H">{Total}</div>
             </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CircleChart;
