import React, { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import axios from "axios";
import { BASE_URL } from "../../../utils/Element";

const statusArray = {
  1: "new_lead",
  2: "buyer_making_offers",
  3: "executed_loi",
  4: "under_contract",
  5: "bov_presented",
  6: "exclusive_listing",
};

const StageDashboardDiposition = ({activeTab}) => {
  const [loading, setLoading] = useState(true);
  const [config] = useAuth();
  const [categories, setCategories] = useState([
    { title: "New Lead", pipelines: [] },
    { title: "BOV Presented", pipelines: [] },
    { title: "Exclusive Listing", pipelines: [] },
    { title: "Executed LOI", pipelines: [] },
    { title: "Under Contract", pipelines: [] },
  ]);

  const PipelineStageAcquisition = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${BASE_URL}/dashboard-pipeline-stage-disposition?active_tab=${activeTab}`,
        config
      );
      
      const pipelineData = response.data.pipeline_stage?.pipeline_status_disposition || {};
      
      const formattedCategories = Object.keys(statusArray).slice(0, 4).map((key, index) => ({
        title: categories[index]?.title || statusArray[key],
        pipelines: pipelineData[statusArray[key]]
          ? pipelineData[statusArray[key]].map((item) => ({
              id: item.id,
              name: item.contact_name,
              lastContacted: item.formatted_created_at,
            }))
          : [],
      }));
      
      setCategories(formattedCategories);
    } catch (error) {
      console.error("Error fetching pipelines:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    PipelineStageAcquisition();
  }, [activeTab]);

  const onDragStart = (e, pipelineId, fromCategoryIndex) => {
    e.dataTransfer.setData("pipelineId", pipelineId);
    e.dataTransfer.setData("fromCategory", fromCategoryIndex);
  };
  
  const updatePipelineStatus = async (pipelineId, fromCategoryIndex, toCategoryIndex) => {
    try {
      const statusKeys = Object.keys(statusArray).map(Number);
      const newStage = statusKeys[toCategoryIndex];
      const oldStage = statusKeys[fromCategoryIndex]; // Source category stage
    
      await axios.put(
        `${BASE_URL}/dashboard-pipeline-stage-status/${pipelineId}`,
        { id: pipelineId, fromStage: oldStage, toStage: newStage }, // Send both stages
        config
      );
  
    } catch (error) {
      console.error("Error updating pipeline status:", error);
    }
  };
  
  const onDrop = (e, toCategoryIndex) => {
    e.preventDefault();
    const pipelineId = parseInt(e.dataTransfer.getData("pipelineId"));
    const fromCategoryIndex = parseInt(e.dataTransfer.getData("fromCategory"));
  
    if (fromCategoryIndex === toCategoryIndex) return;
  
    let newCategories = categories.map((category) => ({
      ...category,
      pipelines: [...category.pipelines],
    }));
  
    const pipelineIndex = newCategories[fromCategoryIndex].pipelines.findIndex(
      (pipeline) => pipeline.id === pipelineId
    );
  
    if (pipelineIndex !== -1) {
      const [movedPipeline] = newCategories[fromCategoryIndex].pipelines.splice(
        pipelineIndex,
        1
      );
      newCategories[toCategoryIndex].pipelines.push(movedPipeline);
    }
  
    setCategories(newCategories);
    updatePipelineStatus(pipelineId, fromCategoryIndex, toCategoryIndex);
  };
  
  const onDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div className="flex w-full h-screen bg-gray-100 p-4 overflow-hidden">
      <div className="flex w-full space-x-2">
        {categories.map((category, categoryIndex) => (
          <div
            key={categoryIndex}
            className="flex-1"
            onDragOver={onDragOver}
            onDrop={(e) => onDrop(e, categoryIndex)}
          >
            <div className="green-bg-H text-white p-2 rounded-t-md text-center font-medium">
              {category.title}
            </div>
            <div className="bg-white h-full overflow-y-auto max-h-[500px]">
              {category.pipelines.map((pipeline) => (
                <div
                  key={pipeline.id}
                  className="p-3 border-b border-gray-100 hover:bg-gray-50 cursor-pointer"
                  draggable
                  onDragStart={(e) => onDragStart(e, pipeline.id, categoryIndex)}
                >
                  <p className="font-medium">{pipeline.name}</p>
                  <p className="text-xs text-gray-500">Last Contacted: {pipeline.lastContacted}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StageDashboardDiposition;