import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NotificationManager } from "react-notifications"
import { BASE_URL } from "../../../utils/Element"
import useAuth from '../../../hooks/useAuth';

function PyramidChart({ Title, Total, activeTab, onClick }) {
  const [pipelineDashboard, setPipelineDashboard] = useState()
  const [loading, setLoading] = useState(true)
  const [config] = useAuth()

  const fetchDashboardData = () => {
    setLoading(true)

    axios
      .get(`${BASE_URL}/dashboard-pipeline?active_tab=${activeTab}`, config)
      .then((res) => {
        setPipelineDashboard(res?.data?.pipeline_dashboard)
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message)
        }
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchDashboardData()
  }, [activeTab])

  return (
    <div className="">
      <div>
        <h2 className="head-2 green-H pt-5 text-center">{Title}</h2>
        <p className="head-5 dark-H py-2 text-center">{Total}</p>
      </div>

      <div className="flex items-center justify-center">
        <div className="w-full max-w-3xl mx-auto px-4">
          {/* New Lead */}
          <div className="mb-2">
            <div
              className="relative h-[3rem] mx-auto"
              style={{ width: "85%", cursor: "pointer" }}
              onClick={() => {
                onClick({
                  new_lead: true,
                  buyer_making_offers: false,
                  executed_loi: false,
                  under_contract: false,
                })
              }}
            >
              <div
                className="absolute inset-0 bg-gray-300"
                style={{ clipPath: "polygon(6% 0%, 94% 0%, 89% 100%, 11% 100%)" }}
              >
                <div className="flex items-center justify-center h-full">
                  <div className="text-center">
                    <div className="head-5 text-white">New Lead</div>
                    <div className="head-5 text-white mt-2">
                      {pipelineDashboard?.pipeline_status_acquisition?.new_lead}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Down Arrow */}
          <div className="mb-2">
            <div className="relative h-[3rem] mx-auto" style={{ width: "70%", cursor: "pointer" }}>
              <div
                className="absolute inset-0 bg-gray-500 flex items-center justify-center"
                style={{
                  clipPath: "polygon(3% 0%, 97% 0%, 92% 100%, 8% 100%)",
                }}
              >
                <div className="flex items-center justify-center h-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-16 h-8 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          {/* Buyer Making Offers */}
          <div className="mb-2">
            <div
              className="relative h-[3rem] mx-auto"
              style={{ width: "60%", cursor: "pointer" }}
              onClick={() => {
                onClick({
                  new_lead: false,
                  buyer_making_offers: true,
                  executed_loi: false,
                  under_contract: false,
                })
              }}
            >
              <div
                className="absolute inset-0"
                style={{ clipPath: "polygon(2% 0%, 98% 0%, 91% 100%, 9% 100%)", backgroundColor: "rgb(139, 168, 145)" }}
              >
                <div className="flex items-center justify-center h-full">
                  <div className="text-center">
                    <div className="head-5 text-white">Buyer Making Offers</div>
                    <div className="head-5 text-white mt-2">
                      {pipelineDashboard?.pipeline_status_acquisition?.buyer_making_offers}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Executed LOI */}
          <div className="mb-2">
            <div
              className="relative h-[3rem] mx-auto"
              style={{ width: "50%", cursor: "pointer" }}
              onClick={() => {
                onClick({
                  new_lead: false,
                  buyer_making_offers: false,
                  executed_loi: true,
                  under_contract: false,
                })
              }}
            >
              <div
                className="absolute inset-0"
                style={{ clipPath: "polygon(2% 0%, 98% 0%, 90% 100%, 10% 100%)", backgroundColor: "rgb(61, 81, 65)" }}
              >
                <div className="flex items-center justify-center h-full">
                  <div className="text-center">
                    <div className="head-5 text-white">Executed LOI</div>
                    <div className="head-5 text-white mt-2">
                      {pipelineDashboard?.pipeline_status_acquisition?.executed_loi}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Under Contract */}
          <div>
            <div
              className="relative h-[3rem] mx-auto"
              style={{ width: "40%", cursor: "pointer" }}
              onClick={() => {
                onClick({
                  new_lead: false,
                  buyer_making_offers: false,
                  executed_loi: false,
                  under_contract: true,
                })
              }}
            >
              <div
                className="absolute inset-0"
                style={{ clipPath: "polygon(2% 0%, 99% 0%, 89% 100%, 10% 100%)", backgroundColor: "rgb(31, 56, 35)" }}
              >
                <div className="flex items-center justify-center h-full">
                  <div className="text-center">
                    <div className="head-5 text-white">Under Contract</div>
                    <div className="head-5 text-white mt-2">
                      {pipelineDashboard?.pipeline_status_acquisition?.under_contract}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default PyramidChart;