import React from "react"
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts"

const BarData = ({ Title, BarGraphData = [], onBarClick }) => {
  const barWidth = 30
  const customYTicks = [0, 10, 15, 20, 25, 30, 35, 40]

  const handleBarClick = (name) => {
    console.log(`Clicked on ${name}`)
    if (onBarClick) {
      const leadType = name === "Buyer" ? "acquisition" : "disposition"
      onBarClick(leadType)
    }
  }

  const renderCustomBar = (props) => {
    const { x, y, width, height, fill, payload } = props
    const color = payload.name === "sjhssh" ? "#2D5B30" : "#D1DBCF"
    return (
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={color}
        cursor="pointer"
        onClick={() => handleBarClick(payload.name)}
      />
    )
  }

  return (
    <div className="light-bg-L h-full w-[50%] p-4">
      {/* Header */}
      <div className="flex justify-between mb-5">
        <h2 className="head-2 dark-H">{typeof Title === "string" ? Title : "Type of Lead"}</h2>
      </div>

      <ResponsiveContainer width="100%" height={378}>
        <BarChart className="dark-M body-S" data={BarGraphData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid stroke="#efefef" vertical={false} />
          <XAxis dataKey="name" axisLine={false} tickLine={false} />
          <YAxis tick={customYTicks} axisLine={false} tickLine={false} />
          <Tooltip formatter={(value) => [`${value}`]} />
          <Bar
            dataKey="count"
            shape={renderCustomBar}
            barSize={barWidth}
            onClick={(data) => handleBarClick(data.name)}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default BarData

