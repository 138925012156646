import React, { useState } from "react";
import Modal from "../Modal/Modal";
import Select from "react-select"

const initialData = {
  lead_type: "",
  lead_status:""
};



const ConfirmationModalBov = ({ showModal, onClose, handleUnderContract }) => {
const [formData, setFormData] = useState(initialData);
 const [error, setError] = useState();
  const statusOptions = [
    { value: 2, label: "Cold" },
    { value: 3, label: "Warm" },
    { value: 1, label: "Hot" },
  ];

  
  const handleChange = (value, name) => {
    setError({ ...error, [name]: "" });
    setFormData({ ...formData, [name]: value });
  };
  return (
    <Modal width="450px" show={showModal} onClose={onClose}>
      <form className="py-3 ">
         <div className="flex-1 mt-4 text-center w-30">
          <p className="head-1 dark-H font-bold">BOV Confirmation</p>
          <p className="py-1 dark-M body-L">Please note that confirming this Pipeline is a final action and cannot be undone.</p>
         </div>

        <div className="mt-1.5">
        <label className="dark-H head-4 mb-2 required:*:">
          Status<span className="red-D">*</span>
          </label>
          <div>
           <Select
             options={statusOptions}
             value={statusOptions.find((option) => option.value === formData.lead_status) || null}
             onChange={(option) => {
              setError({ ...error, lead_status: "" });
              handleChange(option.value, "lead_status");
            }}
             placeholder="Select Status"
             className="body-N"
           />
          </div>
           
           </div>

        <div className="mt-6 flex justify-center">
          <button type="button" onClick={handleUnderContract} className="save-button light-L head-5 green-bg-H px-[30px] py-[12px]">
            Bov
          </button>
          <button type="button" onClick={onClose} className="green-H body-N ml-5">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ConfirmationModalBov;