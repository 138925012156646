import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NotificationManager } from "react-notifications"
import { BASE_URL } from "../../../utils/Element"
import useAuth from '../../../hooks/useAuth';

function DispositionsFunnelChart({ Title, Total , activeTab , onClick}) {
  const [pipelineDashboard , setPipelineDashboard] =useState()
const [loading, setLoading] = useState(true)
const [config] = useAuth()


  const fetchDashboardData = () => {
    setLoading(true);

    axios
      .get(`${BASE_URL}/dashboard-pipeline?active_tab=${activeTab}`, config)
      .then((res) => {
        setPipelineDashboard(res?.data?.pipeline_dashboard);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchDashboardData();
 }, [activeTab]);

  return (
    <div > 
      
      <div>
       <h2 className="head-2 green-H pt-5 text-center">{Title}</h2>
        <p className="head-5 dark-H py-2 text-center">{Total}</p>
      </div>

      <div className="flex items-center justify-center">
        <div className="w-full max-w-3xl mx-auto px-4">
          <div className="mb-2">
            <div 
              className="relative h-[3rem] mx-auto" 
              style={{ width: '85%', cursor: "pointer" }} 
              onClick={() => {
                onClick({
                  new_lead_dispositions: true,
                  bov_presented: false,
                  exclusive_listing: false,
                  executed_loi_dispositions: false,
                  under_contract_dispositions: false,
                })
              }}
            >
              <div 
                className="absolute inset-0 bg-gray-300" 
                style={{ clipPath: 'polygon(6% 0%, 94% 0%, 89% 100%, 11% 100%)' }}
              >
                <div className="flex items-center justify-center h-full">
                  <div className="text-center">
                    <div className="head-5 text-white">New Lead</div>
                    <div className="head-5 text-white mt-2">{pipelineDashboard?.pipeline_status_disposition?.new_lead}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-2">
             <div
              className="relative h-[3rem] mx-auto"
              style={{ width: "70%", cursor: "pointer" }}
              onClick={() => {
                onClick({
                  new_lead_dispositions: false,
                  bov_presented: true,
                  exclusive_listing: false,
                  executed_loi_dispositions: false,
                  under_contract_dispositions: false,
                })
              }}
            >
               <div
               className="absolute inset-0 bg-gray-500 flex items-center justify-center"
            style={{
              clipPath: "polygon(3% 0%, 97% 0%, 92% 100%, 8% 100%)",
               }}
             >
                <div className="flex items-center justify-center h-full">
                  <div className="text-center">
                    <div className="head-5 text-white">BOV Presented</div>
                    <div className="head-5 text-white mt-2">{pipelineDashboard?.pipeline_status_disposition?.bov_presented}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

           <div className="mb-2">
            <div 
              className="relative h-[3rem] mx-auto" 
                style={{ width: '60%', cursor: "pointer" }} 
                onClick={() => {
                  onClick({
                    new_lead_dispositions: false,
                    bov_presented: false,
                    exclusive_listing: true,
                    executed_loi_dispositions: false,
                    under_contract_dispositions: false,
                  })
                }}
            >
              <div 
                className="absolute inset-0" 
                style={{ clipPath: 'polygon(2% 0%, 98% 0%, 91% 100%, 9% 100%)', backgroundColor: 'rgb(139, 168, 145)' }}
              >
                <div className="flex items-center justify-center h-full">
                  <div className="text-center">
                    <div className="head-5 font-semibold text-white">Exclusive Listing</div>
                    <div className="head-5 text-white mt-2">{pipelineDashboard?.pipeline_status_disposition?.exclusive_listing
                    }</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-2">
            <div 
              className="relative h-[3rem] mx-auto" 
              style={{ width: '50%', cursor: "pointer" }} 
              onClick={() => {
                onClick({
                  new_lead_dispositions: false,
                  bov_presented: false,
                  exclusive_listing: false,
                  executed_loi_dispositions: true,
                  under_contract_dispositions: false,
                })
              }}
            >
              <div 
                className="absolute inset-0" 
                style={{ clipPath: 'polygon(2% 0%, 98% 0%, 90% 100%, 10% 100%)', backgroundColor: 'rgb(61, 81, 65)' }}
              >
                <div className="flex items-center justify-center h-full">
                  <div className="text-center">
                    <div className="head-5 text-white">Executed LOI</div>
                    <div className="head-5 text-white mt-2">{pipelineDashboard?.pipeline_status_disposition?.executed_loi}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

   
          <div>
            <div 
              className="relative h-[3rem] mx-auto" 
              style={{ width: '40%', cursor: "pointer" }} 
              onClick={() => {
                onClick({
                  new_lead_dispositions: false,
                  bov_presented: false,
                  exclusive_listing: false,
                  executed_loi_dispositions: false,
                  under_contract_dispositions: true,
                })
              }}
            >
              <div 
                className="absolute inset-0" 
                style={{ clipPath: 'polygon(2% 0%, 99% 0%, 89% 100%, 10% 100%)', backgroundColor: 'rgb(31, 56, 35)' }}
              >
                <div className="flex items-center justify-center h-full">
                  <div className="text-center">
                    <div className="head-5 text-white">Under Contract</div>
                    <div className="head-5 text-white mt-2">{pipelineDashboard?.pipeline_status_disposition?.under_contract}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
  );
}

export default DispositionsFunnelChart;
