import React, { useEffect, useState } from "react"
import { NotificationManager } from "react-notifications"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import axios from "axios"
import moment from "moment"
import useAuth from "../../hooks/useAuth"
import Loader from "../../components/Loader"
import Plus from "../../assets/svgs/Plus.svg"
import { BASE_URL } from "../../utils/Element"
import BaseLayout from "../../layouts/BaseLayout"
import contact from "../../assets/svgs/account.svg"
import ActionsMenu from "../../components/ActionsMenu"
import UpArrow from "../../assets/svgs/up-arrow.svg";
import DownArrow from "../../assets/svgs/down-arrow.svg";
import swap from "../../assets/svgs/swap-vertical.svg"
import sortAsce from "../../assets/svgs/sort-ascending.svg"
import sortDesc from "../../assets/svgs/sort-descending.svg"
import { initialPipelineFilterData } from "../../utils/initialData"
import ContactPagination from "../../components/Pagination/ContactPagination"
import ConfirmationModal from "../../components/ConfirmationModals/ConfirmationModal"
import DeleteConfirmationModal from "../../components/ConfirmationModals/DeleteConfirmationModal"
import LeadDataContainer from "../../pages/Leads/components/LeadDataContainer"
import CircleChart from "../../components/Charts/CircleChart"
import BarData from "../../components/Charts/BarData"
import PipelineFilter from "../Pipeline/PipelineFilter"
import EditpipelineModal from "../Pipeline/Modal/EditpipelineModal"
import ContractModal from "../../components/ConfirmationModals/ContractModal"
import ExecutedAddModal from "./component/ExecutedAddModal"


const ExecutedLOI = () => {
  const [config] = useAuth()
  const navigate = useNavigate()
  const location = useLocation();
  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState(true)
  const [leadsData, setLeadsData] = useState([])
  const [selectedId, setSelectedId] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [expandedRows, setExpandedRows] = useState({});
  const [itemPerPage, setItemPerPage] = useState(50)
  const [sortBy, setSortBy] = useState("id")
  const [sortDirection, setSortDirection] = useState("asc")
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [disableDelete, setDisableDelete] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [showContractModal, setShowContractModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const isSidebarCollapsed = useSelector((state) => state.isSidebarCollapsed)
  const [paginationData, setPaginationData] = useState({ totalItems: 0, from: 0, to: 0, totalPages: 0 })
  const queryParams = new URLSearchParams(location.search);
  const [filterData, setFilterData] = useState(() => {
    const localFilterData = localStorage.getItem("filterData")
    return localFilterData ? JSON.parse(localFilterData) : initialPipelineFilterData
  })

  const handleNext = () => {
    if (currentPage !== paginationData.totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const buildQueryParams = (filters) => {
    let params = `&page=${currentPage}&per_page=${itemPerPage}&search=${search}`

    if (search) {
      const encodedSearch = encodeURIComponent(search);
      params += `&search=${encodedSearch}`;
  }
    const {
      contact_id , associate_id , transaction_type , stage ,min_potential_gcl , max_potential_gcl
    } = filters

    if (contact_id) {
      params += `&contact_id=${encodeURIComponent(contact_id)}`
    }
    if (associate_id) {
      params += `&associate_id=${encodeURIComponent(associate_id)}`
    }
    if (transaction_type?.length > 0) {
      params += `&transaction_type=${encodeURIComponent(transaction_type)}`
    }
    if (stage) {
      params += `&stage=${encodeURIComponent(stage)}`
    }
    if (min_potential_gcl) {
      params += `&min_potential_gcl=${encodeURIComponent(min_potential_gcl)}`
    }
    if (max_potential_gcl) {
      params += `&max_potential_gcl=${encodeURIComponent(max_potential_gcl)}`
    }
    if (sortDirection && sortBy !== "id") {
      params += `&sort_direction=${sortDirection}`;
    }
    if (sortBy !== "id" && sortBy !== undefined) {
      params += `&sort_by=${sortBy}`;
    }
  
    return params
  }

  const fetchPipeline = (filters) => {
    const queryParams = buildQueryParams(filters)
    axios
      .get(`${BASE_URL}/pipelines?avtive_tab=${activeTab}${queryParams}`, config)
      .then((res) => {
        const value = res?.data?.pipelinesList
        setLeadsData(value?.data || [])
        setPaginationData({
          totalItems: value?.total ?? 0,
          from: value?.from ?? 0,
          to: value?.to ?? 0,
          totalPages: value?.last_page ?? 0,
        })
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message)
        }
      })
      .finally(() => setLoading(false))
  }

  const handleUnderContract = () => {
    axios
      .delete(`${BASE_URL}/mark-as-under-contract/${selectedId}`, config)
      .then((res) => {
        setSelectedId("");
        onSuccess();
        setShowContractModal(false);
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPipeline(filterData)
  }, [sortDirection, sortBy])

  useEffect(() => {
    onSuccess()
  }, [search, currentPage, itemPerPage, activeTab])

  const onSuccess = (filters) => {
    setLoading(true)
    if (filters) {
        fetchPipeline(filters)
    } else {
        fetchPipeline(filterData)
    }
  }

  const handleDelete = () => {
    setDisableDelete(true)
    axios
      .delete(`${BASE_URL}/delete-pipeline/${selectedId}`, config)
      .then((res) => {
        setSelectedId("")
        onSuccess()
        setShowDeleteModal(false)
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message)
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message)
        }
      })
      .finally(() => setDisableDelete(false))
  }
   useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams) {
        queryParams.set('search', search);
      } else {
        queryParams.delete('search'); 
      }
      navigate({ search: queryParams.toString() }, { replace: true });
    }, [search, navigate, location.search]);



  const data = [
    { value: "22", name: "26+ Days 1", color: "#2D5B30" },
    { value: "30", name: "15-25 Days 2", color: "grey" },
    { value: "67", name: "0-14 Days 6", color: "black" },
  ];

  const BarGraphData = [
    { name: "Jan", count: 100 },
    { name: "Feb", count: 15 },
    { name: "Mar", count: 15 },
    { name: "Apr", count: "" },
    { name: "May", count: "" },
    { name: "jun", count: "" },
    { name: "Jul", count: "" },
    { name: "Aug", count: 25 },
    { name: "Sep", count: "" },
    { name: "Oct", count: 15 },
    { name: "Nov", count: 55 },
    { name: "Dec", count: 10 },
  ];

  const toggleRowDetails = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle only the clicked row
    }));
  };
  


  return (
    <BaseLayout>
      <div className="contact-header">
        <p className="head-1 dark-H">Executed LOI</p>
        <div className="md:flex block gap-4 items-center">
          <div className="flex gap-4 justify-between items-center">
            <PipelineFilter
              filterData={filterData}
              onSetFilterData={(value) => {
                setFilterData(value)
              }}
              from="Pipeline"
              onCallApiAgain={(filters) => onSuccess(filters)}
            />
            <div className="search-box contacts">
              <input
                type="text"
                className="body-S"
                placeholder="Search contact name"
                value={search}
                onChange={(e) => {
                  const newValue = e.target.value
                  setSearch(newValue.startsWith(" ") ? newValue.trimStart() : newValue)
                }}
                style={{ border: "1px solid #D8D8D8", marginTop: "0px" }}
              />
            </div>
            <button onClick={() => setShowAddModal(true)} className="add-contact-button green-bg-H light-L body-S">
              <img className="mr-2" src={Plus || "/placeholder.svg"} alt="plus" /> Add
            </button>
          </div>
        </div>
      </div>

      <div className="task-tabs mx-1 mt-2 flex justify-between body-L dark-M mb-4">
        <div className="flex gap-4">
          <p
            role="button"
            className={`${activeTab === 0 ? "head-4 dark-H active" : ""} pb-3`}
            onClick={() => {
              setActiveTab(0)
              setCurrentPage(1)
            }}
          >
            Team Dashboard
          
          </p>
          <p
            role="button"
            className={`${activeTab === 1 ? "head-4 dark-H active" : ""} pb-3`}
            onClick={() => {
              setActiveTab(1)
              setCurrentPage(1)
            }}
          >
           My Dashboard
          </p>
          <p
            role="button"
            className={`${activeTab === 2 ? "head-4 dark-H active" : ""} pb-3`}
            onClick={() => {
              setActiveTab(2)
              setCurrentPage(1)
            }}
          >
             My Executed LOI
          </p>
          <p
            role="button"
            className={`${activeTab === 3 ? "head-4 dark-H active" : ""} pb-3`}
            onClick={() => {
              setActiveTab(3)
              setCurrentPage(1)
            }}
          >
            Team Executed LOI
          </p>
        </div>
      </div>


      {(activeTab === 2 || activeTab === 3) && (
      <div className="light-bg-L pb-3 table-container">
        <div className={`${isSidebarCollapsed ? "collapsed-width" : "width"} table-info light-bg-L`}>
          {loading ? (
            <Loader />
          ) : leadsData?.length === 0 ? (
            <p className="body-N text-center mt-6 p-10">No Pipline Available</p>
          ) : (
            <table className="contact-table light-bg-L">
              <thead>
                <tr className="uppercase body-N dark-M">

                  <th>
                    <p>Details</p>
                  </th>

                  <th className="green-H">
                    <div className="table-header">
                      EXECUTED LOI DATE
                      <img
                        role="button"
                        src={sortBy !== "bds" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("bds")
                          if (sortDirection === "desc") {
                            setSortDirection("asc")
                          } else {
                            setSortDirection("desc")
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>

                  <th>
                    <div className="table-header">
                    PROPERTY NAME
                      <img
                        role="button"
                        src={sortBy !== "broker" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        alt="icon"
                        onClick={() => {
                          setSortBy("broker")
                          if (sortDirection === "desc") {
                            setSortDirection("asc")
                          } else {
                            setSortDirection("desc")
                          }
                        }}
                      />
                    </div>
                  </th>

                  <th>
                    <div className="table-header">
                       NOI
                      <img
                        role="button"
                        src={sortBy !== "created_at" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        alt="icon"
                        onClick={() => {
                          setSortBy("created_at")
                          if (sortDirection === "desc") {
                            setSortDirection("asc")
                          } else {
                            setSortDirection("desc")
                          }
                        }}
                      />
                    </div>
                  </th>

                  <th>
                    <div className="table-header">
                       CONTRACT PRICE
                      <img
                        role="button"
                        src={sortBy !== "transaction_type" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        alt="icon"
                        onClick={() => {
                          setSortBy("transaction_type")
                          if (sortDirection === "desc") {
                            setSortDirection("asc")
                          } else {
                            setSortDirection("desc")
                          }
                        }}
                      />
                    </div>
                  </th>

                  <th>
                    <div className="table-header">
                      CONTRACT CAP RATE
                      <img
                        role="button"
                        src={sortBy !== "stage" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        alt="icon"
                        onClick={() => {
                          setSortBy("stage")
                          if (sortDirection === "desc") {
                            setSortDirection("asc")
                          } else {
                            setSortDirection("desc")
                          }
                        }}
                      />
                    </div>
                  </th>

                  <th>
                    <div className="table-header">
                      % ASKING PRICE
                      <img
                        role="button"
                        src={sortBy !== "contact_id" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        alt="icon"
                        onClick={() => {
                          setSortBy("contact_id")
                          if (sortDirection === "desc") {
                            setSortDirection("asc")
                          } else {
                            setSortDirection("desc")
                          }
                        }}
                      />
                    </div>
                  </th>

                  <th>
                    <div className="table-header">
                      GCI TO COMPANY
                      <img
                        role="button"
                        src={sortBy !== "type" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        alt="icon"
                        onClick={() => {
                          setSortBy("type")
                          if (sortDirection === "desc") {
                            setSortDirection("asc")
                          } else {
                            setSortDirection("desc")
                          }
                        }}
                      />
                    </div>
                  </th>
                   
                   <th>
                    <div className="table-header">Actions</div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {leadsData?.map((el, idx) => (
                 <React.Fragment key={idx}>
                        <tr className="dark-H body-N">
                          <td>
                            <img 
                              role="button"
                              src={expandedRows[el.id] ? UpArrow : DownArrow}
                              alt="icon"
                              className="sidebar-icons"
                              onClick={() => toggleRowDetails(el.id)}
                            />
                          </td>
                          <td className="head-5 capitalize">
                            <Link to={`/property/${el?.property_id}`}
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(`/property/${el?.property_id}`); 
                              }}>
                              {el?.property?.property_name}
                            </Link>
                          </td>
                          <td>{el?.property?.city}</td>
                          <td>{el?.property?.state}</td>
                          <td>{moment(el?.created_at).tz('America/New_York').format("MM/DD/YY")}</td>
                          <td className="flex items-center gap-2">
                            <p className="tags green-H body-S capitalize">{el?.type} Counter</p>
                          </td>
                          <td>{el?.property?.anual_rent ? `$ ${el?.property?.anual_rent?.toLocaleString()}` : ""}</td>
                          <td className="text-center">{el?.offer_cap_rate ? `${el?.offer_cap_rate?.toLocaleString()} %` : ""}</td>
                          <td>{el?.offer_price ? `$ ${el?.offer_price?.toLocaleString()}` : ""}</td>
                          <td className="text-center">
                            {el?.percent_of_asking_price
                              ? `${Number(el?.percent_of_asking_price)?.toLocaleString(undefined, { maximumFractionDigits: 2 })} %`
                              : ""}
                          </td>
                          <td className="flex gap-3 items-center w-full justify-center">
                            <ActionsMenu
                              from="Offers"
                              handleEdit={() => {
                                setShowEditModal(true);
                                setSelectedId(el?.id);
                                // setleadId(el?.lead_id);
                              }}
                              handleDelete={() => {
                                setSelectedId(el?.id);
                                setShowDeleteModal(true);
                              }}
                              showOtherOption={true}
                              otherOptionTitle={"New Counter Offer"}
                              handleOtherOption={() => {
                                setShowAddModal(true);
                                setSelectedId(el?.id);
                              }}
                              otherUnderContractTitle={<> Convert to <br /> Under Contract</>}
                              handleUnderContract={() => {
                                setSelectedId(el?.id);
                                setShowContractModal(true);
                              }}
                              otherConverttoExecutedLOI={<> Convert to <br />Executed LOI</>}
                              handleConverttoExecutedLOI={() => {
                                setSelectedId(el?.id);
                              }}
                            />
                          </td>
                        </tr>
                  
                        {expandedRows[el.id] && (
                           <tr>
                             <td colSpan="11">
                             <div className="expanded-row-content p-4 bg-[rgb(244,244,244)] rounded-md">
                                 <div className="">
     
                                 <div className="flex items-center gap-5 ">
                                   
                                 <div>
                                     <p className="dark-H head-4">Acquistion</p>
                                     <Link 
                                       to={`/contact/${el?.buyer_contact_id}`}
                                       className="body-N  dark-H "
                                       onClick={(e) => {
                                         e.preventDefault();
                                         navigate(`/contact/${el?.buyer_contact_id}`);
                                       }}
                                     >
                                       {el?.acquistion_name || "N/A"}
                                     </Link>
                                   </div>
     
                                   <div>
                                     <p className="dark-H head-4">Disposition</p>
                                     <Link 
                                       to={`/contact/${el?.seller_contact_id}`}
                                       className="body-N  dark-H "
                                       onClick={(e) => {
                                         e.preventDefault();
                                         navigate(`/contact/${el?.seller_contact_id}`);
                                       }}
                                     >
                                       {el?.disposition_name || "N/A"}
                                     </Link>
                                   </div>
     
                                   <div>
                                     <p className="dark-H head-4 mb-2">Asking Cap Rate</p>
                                     <p className="body-N  dark-H ">
                                       {el?.property?.asking_cap_rate ? `${el.property.asking_cap_rate.toLocaleString()} %` : "N/A"}
                                     </p>
                                   </div>
     
                                   <div>
                                     <p className="dark-H head-4 mb-2">Asking Price</p>
                                     <p className="body-N  dark-H ">
                                       {el?.property?.asking_price ? `$ ${el.property.asking_price.toLocaleString()}` : "N/A"}
                                     </p>
                                   </div>
     
                                  </div>

                                  <div className="flex items-center gap-5 mt-4">
                                
                                <div>
                                  <p className="dark-H head-4 mb-2">Seller Broker</p>
                                  <p className="body-N  dark-H">
                                    {el?.seller_broker?.first_name} {el?.seller_broker?.last_name || "N/A"}
                                  </p>
                                </div>
                      
                                <div>
                                  <p className="dark-H head-4 mb-2">Seller BDS</p>
                                  <p className="body-N  dark-H">
                                    {el?.seller_bds?.first_name} {el?.seller_bds?.last_name || "N/A"}
                                  </p>
                                </div>
                      
                                <div>
                                  <p className="dark-H head-4 mb-2">Buyer Broker</p>
                                  <p className="body-N  dark-H">
                                    {el?.buyer_broker?.first_name} {el?.buyer_broker?.last_name || "N/A"}
                                  </p>
                                </div>
                    
                                <div>
                                  <p className="dark-H head-4 mb-2">Buyer BDS</p>
                                  <p className="body-N  dark-H">
                                    {el?.buyer_bds?.first_name} {el?.buyer_bds?.last_name || "N/A"}
                                  </p>
                                </div>
                                 
                                 </div>
                                   
                                </div>
                               </div>
                             </td>
                           </tr>
                         )}
                 </React.Fragment>
                 ))}
              </tbody>
            </table>
          )}
        </div>

         {leadsData?.length > 0 && (
          <ContactPagination
            activeTab={activeTab}
            paginationData={paginationData}
            handleNext={handleNext}
            handlePrev={handlePrev}
            onSuccess={onSuccess}
            itemPerPage={itemPerPage}
            handleItemPerPageSelect={(value) => {
              setItemPerPage(value)
              setCurrentPage(1)
            }}
          />
        )}
      </div>
      )}

     {(activeTab === 0 || activeTab === 1) && (
        <>
         <div className="">
        <div className="w-auto">
        <div className="flex items-center gap-3">
         <LeadDataContainer text="NEW EXECUTED LOI THIS QUARTER" number="3.0" className="flex-1" />
         <LeadDataContainer text="TOTAL CURRENT EXECUTED LOI" number="32" className="flex-1" />
         <LeadDataContainer text="EXECUTED LOI TOTAL VOLUME" number="3" className="flex-1" />
         <LeadDataContainer text="CURRENT POTENTIAL GCI TO COMPANY" number="30" className="flex-1" />
         <LeadDataContainer text="AVG. COMMISSION PER TRANSACTION" number="30" className="flex-1" />
        
       </div>

           <div className="flex gap-3 ">
          
           <div className="w-[25%]">
            <LeadDataContainer text="EXECUTED LOI BUYER TRANSACTIONS" number="3" className="flex-1" />
            <LeadDataContainer text="EXECUTED LOI SELLER TRANSACTIONS" number="30" className="flex-1" />
            </div>


            <div className="flex gap-3 items-center w-[75%] ">
            <CircleChart
              Title="DAYS SINCE EXECUTED LOI"
              data={data} />

              <BarData 
              Title = "MONTHLY GAIN IN POTENTIAL GCI"
              BarGraphData={BarGraphData}
              />
            </div>
         </div>

        </div>

        </div>
        
        </>
       )}

      <ExecutedAddModal    showModal={showAddModal} onClose={() => setShowAddModal(false)} onCallApi={onSuccess} />

      <EditpipelineModal
        showModal={showEditModal}
        onClose={() => setShowEditModal(false)}
        id={selectedId}
        onCallApi={() => {
          setSelectedId("")
          onSuccess()
        }}

      />

      <ContractModal
        showModal={showContractModal}
        onClose={() => setShowContractModal(false)}
        handleUnderContract={handleUnderContract}
      />

      <ConfirmationModal
        showModal={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        handleConfirm={handleDelete}
      />

      <DeleteConfirmationModal
        showModal={showDeleteModal}
        disable={disableDelete}
        onClose={() => setShowDeleteModal(false)}
        handleDelete={handleDelete}
      />
    </BaseLayout>
  )
}

export default ExecutedLOI;

