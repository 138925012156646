"use client"

import { useEffect, useState } from "react"
import { NotificationManager } from "react-notifications"
import { Link, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import Select from "react-select"
import axios from "axios"
import moment from "moment"
import useAuth from "../../hooks/useAuth"
import AddLeadModal from "./AddLeadModal"
import EditLeadModal from "./EditLeadModal"
import Loader from "../../components/Loader"
import Plus from "../../assets/svgs/Plus.svg"
import { BASE_URL } from "../../utils/Element"
import BaseLayout from "../../layouts/BaseLayout"
import LeadsFilter from "./components/LeadsFilter"
import contact from "../../assets/svgs/account.svg"
import ActionsMenu from "../../components/ActionsMenu"
import swap from "../../assets/svgs/swap-vertical.svg"
import sortAsce from "../../assets/svgs/sort-ascending.svg"
import sortDesc from "../../assets/svgs/sort-descending.svg"
import { initialLeadFilterData } from "../../utils/initialData"
import ContactPagination from "../../components/Pagination/ContactPagination"
import DeleteConfirmationModal from "../../components/ConfirmationModals/DeleteConfirmationModal"
import LeadDataContainer from "./components/LeadDataContainer"
import CircleChart from "../../components/Charts/CircleChart"
import LeadDataContainer2 from "./components/LeadDataContainer2"
import BarData from "../../components/Charts/BarData"
import ConvertPipelineModal from "./components/ConvertPipelineModal"

const Leads = () => {
  const [config] = useAuth()
  const navigate = useNavigate()
  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState(true)
  const [leadsData, setLeadsData] = useState([])
  const [selectedId, setSelectedId] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [itemPerPage, setItemPerPage] = useState(50)
  const [sortBy, setSortBy] = useState("id")
  const [leadDashboard, setLeadDashboard] = useState()
  const [sortDirection, setSortDirection] = useState("desc")
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [disableDelete, setDisableDelete] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [rowStatuses, setRowStatuses] = useState({})
  const [activeTab, setActiveTab] = useState(0)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [filterStatus, setFilterStatus] = useState({
    this_week: false,
    lead_not_contacted: false,
  })
  const isSidebarCollapsed = useSelector((state) => state.isSidebarCollapsed)
  const [paginationData, setPaginationData] = useState({ totalItems: 0, from: 0, to: 0, totalPages: 0 })
  const [filterData, setFilterData] = useState(() => {
    const localFilterData = localStorage.getItem("filterData")
    return localFilterData ? JSON.parse(localFilterData) : initialLeadFilterData
  })

  const statusOptions = [
    { value: 2, label: "Cold" },
    { value: 3, label: "Warm" },
    { value: 1, label: "Hot" },
  ]

  const colorMap = { 1: "#ff0000", 3: "#ffb952", 2: "blue" }

  const handleNext = () => {
    if (currentPage !== paginationData.totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleStatusChange = (value, leadId) => {
    setRowStatuses((prevStatuses) => ({
      ...prevStatuses,
      [leadId]: value,
    }))

   
    const selectedOption = statusOptions.find((option) => option.value === value)

    axios
      .post(
        `${BASE_URL}/lead-status-change/${leadId}`,
        {
          lead_status: value,
          type: selectedOption?.label, 
        },
        config,
      )
      .then((res) => {
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message)
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message)
        }
      })
  }

  const buildQueryParams = (filters) => {
    let params = `&page=${currentPage}&per_page=${itemPerPage}&search=${search}`
    const {
      bds,
      broker,
      lead_source,
      created_at,
      created_end_date,
      created_start_date,
      contact,
      lead_type,
      link_id,
      property,
      buyer_id,
      last_contact,
      lastContactSentDate,
      lastContactSentEdate,
      lastContactSentSdate,
      within_last_day,
      longer_than_day,
      date_category,
      this_week,
      lead_not_contacted,
    } = filters

    if (bds) {
      params += `&bds=${encodeURIComponent(bds)}`
    }
    if (broker) {
      params += `&broker=${encodeURIComponent(broker)}`
    }
    if (lead_source?.length > 0) {
      params += `&lead_source=${encodeURIComponent(lead_source)}`
    }
    if (created_at) {
      params += `&created_at=${encodeURIComponent(created_at)}`
    }
    if (created_start_date) {
      params += `&created_start_date=${encodeURIComponent(created_start_date)}`
    }
    if (created_end_date) {
      params += `&created_end_date=${encodeURIComponent(created_end_date)}`
    }
    if (contact && contact.id) {
      params += `&contact=${encodeURIComponent(contact.id)}`
    }
    if (lead_type) {
      params += `&lead_type=${encodeURIComponent(lead_type === "acquisition" ? 1 : 0)}`
    }
    if (
      (last_contact && lastContactSentDate !== "" && lastContactSentDate !== undefined) ||
      (last_contact &&
        lastContactSentSdate !== "" &&
        lastContactSentEdate !== "" &&
        lastContactSentSdate !== undefined &&
        lastContactSentEdate !== undefined)
    ) {
      params += `&last_contact=${encodeURIComponent(last_contact)}`
    }
    if (lastContactSentDate !== "" && lastContactSentDate !== undefined) {
      params += `&last_update_date=${encodeURIComponent(lastContactSentDate)}`
    }
    if (lastContactSentSdate !== "" && lastContactSentSdate !== undefined) {
      params += `&last_update_start_date=${encodeURIComponent(lastContactSentSdate)}`
    }
    if (lastContactSentEdate !== "" && lastContactSentEdate !== undefined) {
      params += `&last_update_end_date=${encodeURIComponent(lastContactSentEdate)}`
    }
    if (within_last_day !== "" && within_last_day !== undefined) {
      params += `&within_last_day=${encodeURIComponent(within_last_day)}`
    }
    if (longer_than_day !== "" && longer_than_day !== undefined) {
      params += `&longer_than_day=${encodeURIComponent(longer_than_day)}`
    }
    if (date_category !== undefined) {
      params += `&date_category=${encodeURIComponent(date_category)}`
    }
    if (link_id) {
      params += `&link_id=${encodeURIComponent(link_id)}`
    }
    if (property?.id) {
      params += `&property_id=${encodeURIComponent(property?.id)}`
    }
    if (buyer_id) {
      params += `&buyer_id=${encodeURIComponent(buyer_id)}`
    }
    if (this_week) {
      params += `&this_week=true`
    }
    if (lead_not_contacted) {
      params += `&lead_not_contacted=true`
    }
    if (sortDirection && sortBy !== "id") {
      params += `&sort_direction=${sortDirection}`
    }
    if (sortBy !== "id") {
      params += `&sort_by=${sortBy}`
    }

    return params
  }

  const fetchLeads = (filters) => {
    const queryParams = buildQueryParams({
      ...filters,
      this_week: filterStatus.this_week,
      lead_not_contacted: filterStatus.lead_not_contacted,
    })

    axios
      .get(`${BASE_URL}/list-leads?active_tab=${activeTab}${queryParams}`, config)
      .then((res) => {
        const value = res?.data?.leadsList
        setLeadsData(value?.data || [])
        setPaginationData({
          totalItems: value?.total ?? 0,
          from: value?.from ?? 0,
          to: value?.to ?? 0,
          totalPages: value?.last_page ?? 0,
        })
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message)
        }
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchLeads(filterData)
  }, [sortDirection, sortBy, filterStatus])

  useEffect(() => {
    onSuccess()
  }, [search, currentPage, itemPerPage])

  const onSuccess = (filters) => {
    setLoading(true)
    if (filters) {
      fetchLeads(filters)
    } else {
      fetchLeads(filterData)
    }
  }

  const handleDelete = () => {
    setDisableDelete(true)
    axios
      .delete(`${BASE_URL}/delete-lead/${selectedId}`, config)
      .then((res) => {
        setSelectedId("")
        onSuccess()
        setShowDeleteModal(false)
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message)
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message)
        }
      })
      .finally(() => setDisableDelete(false))
  }

  const handleConfirm = () => {
    axios
      .post(`${BASE_URL}/markAs-Proposal/${selectedId}`, { mark_as_proposal: true }, config)
      .then((res) => {
        onSuccess()
        setShowConfirmModal(false)
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message)
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message)
        }
      })
  }

  const data = leadDashboard?.lead_source_leads

  const BarGraphData = [
    { name: "Buyer", count: leadDashboard?.lead_type_buyer },
    { name: "Seller", count: leadDashboard?.lead_type_seller },
  ]

  const fetchDashboardData = () => {
    setLoading(true)
    axios
      .get(`${BASE_URL}/dashboard-lead?active_tab=${activeTab}`, config)
      .then((res) => {
        setLeadDashboard(res?.data?.lead_dashboard)
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message)
        }
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchDashboardData()
  }, [activeTab])

  return (
    <BaseLayout>
      <div className="contact-header">
        <p className="head-1 dark-H">Leads</p>
        <div className="md:flex block gap-4 items-center">
          <div className="flex gap-4 justify-between items-center">
            <LeadsFilter
              filterData={filterData}
              onSetFilterData={(value) => {
                setFilterData(value)
              }}
              from="lead"
              onCallApiAgain={(filters) => onSuccess(filters)}
            />
            <div className="search-box contacts">
              <input
                type="text"
                className="body-S"
                placeholder="Search contact name"
                value={search}
                onChange={(e) => {
                  const newValue = e.target.value
                  setSearch(newValue.startsWith(" ") ? newValue.trimStart() : newValue)
                }}
                style={{ border: "1px solid #D8D8D8", marginTop: "0px" }}
              />
            </div>
            <button onClick={() => setShowAddModal(true)} className="add-contact-button green-bg-H light-L body-S">
              <img className="mr-2" src={Plus || "/placeholder.svg"} alt="plus" /> Add
            </button>
          </div>
        </div>
      </div>

      <div className="task-tabs mx-1 mt-2 flex justify-between body-L dark-M mb-4">
        <div className="flex gap-4">
          {["Team Dashboard", "My Dashboard", "Team Leads", "My Leads"].map((tab, index) => (
            <p
              key={index}
              role="button"
              className={`${activeTab === index ? "head-4 dark-H active" : ""} py-1`}
              onClick={() => {
                setActiveTab(index)
                setCurrentPage(1)
                // Reset filterStatus when switching tabs
                setFilterStatus({
                  this_week: false,
                  lead_not_contacted: false,
                })
              }}
            >
              {tab}
            </p>
          ))}
        </div>
      </div>

      {(activeTab === 2 || activeTab === 3) && (
        <div className="light-bg-L pb-3 table-container">
          <div className={`${isSidebarCollapsed ? "collapsed-width" : "width"} table-info light-bg-L`}>
            {loading ? (
              <Loader />
            ) : leadsData?.length === 0 ? (
              <p className="body-N text-center mt-6 p-10">No Leads Available</p>
            ) : (
              <table className="contact-table light-bg-L">
                <thead>
                  <tr className="uppercase body-N dark-M">
                    <th className="green-H">
                      <div className="table-header">
                        Bds
                        <img
                          role="button"
                          src={sortBy !== "bds" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          onClick={() => {
                            setSortBy("bds")
                            if (sortDirection === "desc") {
                              setSortDirection("asc")
                            } else {
                              setSortDirection("desc")
                            }
                          }}
                          alt="icon"
                        />
                      </div>
                    </th>
                    <th>
                      <div className="table-header">
                        Broker
                        <img
                          role="button"
                          src={sortBy !== "broker" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          alt="icon"
                          onClick={() => {
                            setSortBy("broker")
                            if (sortDirection === "desc") {
                              setSortDirection("asc")
                            } else {
                              setSortDirection("desc")
                            }
                          }}
                        />
                      </div>
                    </th>

                    <th>
                      <div className="table-header">
                        Lead Date
                        <img
                          role="button"
                          src={sortBy !== "created_at" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          alt="icon"
                          onClick={() => {
                            setSortBy("created_at")
                            if (sortDirection === "desc") {
                              setSortDirection("asc")
                            } else {
                              setSortDirection("desc")
                            }
                          }}
                        />
                      </div>
                    </th>

                    <th>
                      <div className="table-header">
                        Last Contacted
                        <img
                          role="button"
                          src={sortBy !== "last_update_date" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          alt="icon"
                          onClick={() => {
                            setSortBy("last_update_date")
                            if (sortDirection === "desc") {
                              setSortDirection("asc")
                            } else {
                              setSortDirection("desc")
                            }
                          }}
                        />
                      </div>
                    </th>

                    <th>
                      <div className="table-header">
                        Lead Source
                        <img
                          role="button"
                          src={sortBy !== "lead_source_id" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          alt="icon"
                          onClick={() => {
                            setSortBy("lead_source_id")
                            if (sortDirection === "desc") {
                              setSortDirection("asc")
                            } else {
                              setSortDirection("desc")
                            }
                          }}
                        />
                      </div>
                    </th>

                    <th>
                      <div className="table-header">
                        Contact Name
                        <img
                          role="button"
                          src={sortBy !== "contact_id" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          alt="icon"
                          onClick={() => {
                            setSortBy("contact_id")
                            if (sortDirection === "desc") {
                              setSortDirection("asc")
                            } else {
                              setSortDirection("desc")
                            }
                          }}
                        />
                      </div>
                    </th>

                    <th>
                      <div className="table-header">
                        Lead Type
                        <img
                          role="button"
                          src={sortBy !== "type" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          alt="icon"
                          onClick={() => {
                            setSortBy("type")
                            if (sortDirection === "desc") {
                              setSortDirection("asc")
                            } else {
                              setSortDirection("desc")
                            }
                          }}
                        />
                      </div>
                    </th>

                    <th>
                      <div className="table-header">
                        Property /
                        <br />
                        Acquisition Criteria
                        <img
                          role="button"
                          src={sortBy !== "link_id" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          alt="icon"
                          onClick={() => {
                            setSortBy("link_id")
                            if (sortDirection === "desc") {
                              setSortDirection("asc")
                            } else {
                              setSortDirection("desc")
                            }
                          }}
                        />
                      </div>
                    </th>

                    <th>
                      <div className="table-header">
                        Status
                        <img
                          role="button"
                          src={sortBy !== "lead_status" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          alt="icon"
                          onClick={() => {
                            setSortBy("lead_status")
                            if (sortDirection === "desc") {
                              setSortDirection("asc")
                            } else {
                              setSortDirection("desc")
                            }
                          }}
                        />
                      </div>
                    </th>

                    <th>
                      <div className="table-header">Actions</div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {leadsData?.map((el, idx) => (
                    <tr key={idx} className="dark-H body-N">
                      <td className="head-5 flex items-center gap-2 capitalize">
                        <Link
                          to={`/touch-plan/${el?.id}`}
                          onClick={(e) => {
                            e.preventDefault()
                            if (el?.bds?.id) {
                              navigate(`/user/${el?.bds?.id}`)
                            }
                          }}
                          rel="noopener noreferrer"
                        >
                          {el?.bds?.first_name} {el?.bds?.last_name}
                        </Link>
                      </td>

                      <td className="capitalize head-5">
                        <Link
                          to={`/user/${el?.broker?.id}`}
                          onClick={(e) => {
                            e.preventDefault()
                            if (el?.broker?.id) {
                              navigate(`/user/${el?.broker?.id}`)
                            }
                          }}
                          rel="noopener noreferrer"
                        >
                          {el?.broker?.first_name} {el?.broker?.last_name}
                        </Link>
                      </td>
                      <td className="dark-M">{moment(el?.created_at).tz("America/New_York").format("MM/DD/YY")}</td>
                      <td className="dark-M">
                        {el?.contact?.get_latest_activity?.date_connected
                          ? moment(el?.contact?.get_latest_activity?.date_connected)
                              .tz("America/New_York")
                              .format("MM/DD/YY")
                          : ""}{" "}
                        <span className="dark-M body-S">
                          {el?.contact?.get_latest_activity?.date_connected
                            ? ` (${moment(el?.contact?.get_latest_activity?.date_connected).fromNow()})`
                            : ""}
                        </span>
                      </td>

                      <td className="dark-M">{el?.lead_source?.name}</td>

                      <td className="flex items-center head-5 green-H gap-2 capitalize">
                        <Link
                          to={`/contact/${el?.contact?.id}`}
                          onClick={(e) => {
                            e.preventDefault()
                            if (el?.contact?.id) {
                              window.open(`/contact/${el?.contact?.id}`, "_blank", "noopener,noreferrer")
                            }
                          }}
                          rel="noopener noreferrer"
                          className="flex items-center"
                        >
                          {(el?.contact?.first_name || el?.contact?.last_name) && (
                            <img src={contact || "/placeholder.svg"} alt="contact" className="sidebar-icons" />
                          )}
                          {el?.contact?.first_name} {el?.contact?.last_name}
                        </Link>
                      </td>

                      <td>{el?.lead_type === 0 ? "Disposition" : "Acquisition"}</td>

                      <td className="head-5 capitalize">
                        <Link
                          to={el?.lead_type === 0 ? `/property/${el?.link?.id}` : `/buyer/${el?.link?.id}`}
                          rel="noopener noreferrer"
                          onClick={(e) => {
                            e.preventDefault()
                            if (el?.lead_type === 0) {
                              navigate(`/property/${el?.link?.id}`)
                            } else {
                              navigate(`/buyer/${el?.link?.id}`)
                            }
                          }}
                        >
                          {el?.lead_type === 0
                            ? el?.link?.property_name
                            : (el?.link?.property_type?.[0]?.type ?? "Link")}
                        </Link>
                      </td>

                      <td>
                        <Select
                          options={statusOptions}
                          value={
                            statusOptions.find((option) => option.value === (rowStatuses[el.id] || el.lead_status)) ||
                            null
                          }
                          onChange={(option) => handleStatusChange(option.value, el.id)}
                          placeholder="Status"
                          className="body-N lead-width"
                          styles={{
                            singleValue: (base, { data }) => ({ ...base, color: colorMap[data.value] }),
                            option: (base, { isFocused }) => ({
                              ...base,
                              backgroundColor: isFocused ? "#f0f0f0" : "white",
                              color: "black",
                            }),
                          }}
                        />
                      </td>

                      <td className="flex gap-3 items-center w-full justify-center">
                        <ActionsMenu
                          handleEdit={() => {
                            setShowEditModal(true)
                            setSelectedId(el?.id)
                          }}
                          handleDelete={() => {
                            setSelectedId(el?.id)
                            setShowDeleteModal(true)
                          }}
                          showOtherOption={el.type === 0 ? true : false}
                          otherOptionTitle={"Convert to Pipeline"}
                          handleOtherOption={() => {
                            setShowConfirmModal(true)
                            setSelectedId(el?.id)
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          {leadsData?.length > 0 && (
            <ContactPagination
              activeTab={activeTab}
              paginationData={paginationData}
              handleNext={handleNext}
              handlePrev={handlePrev}
              onSuccess={onSuccess}
              itemPerPage={itemPerPage}
              handleItemPerPageSelect={(value) => {
                setItemPerPage(value)
                setCurrentPage(1)
              }}
            />
          )}
        </div>
      )}

      {(activeTab === 0 || activeTab === 1) && (
        <>
          <div className="flex gap-3">
            <div className="w-[65%]">
              <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
                <div className="h-full">
                  <LeadDataContainer
                    text="NEW LEADS THIS WEEK"
                    number={leadDashboard?.lead_week_count}
                    className="min-h-[8rem] w-full"
                    onClick={() => {
                      setActiveTab(2)
                      setCurrentPage(1)
                      setFilterStatus({
                        this_week: true,
                        lead_not_contacted: false,
                      })
                    }}
                  />
                </div>
                <div className="h-full">
                  <LeadDataContainer
                    text="TOTAL LEADS"
                    number={leadDashboard?.lead_count}
                    className="min-h-[8rem] w-full"
                    onClick={() => {
                      setActiveTab(2)
                      setCurrentPage(1)
                      setFilterStatus({
                        this_week: false,
                        lead_not_contacted: false,
                      })
                      // Use setTimeout to ensure state is updated before the component re-renders
                      setTimeout(() => {
                        fetchLeads(filterData)
                      }, 0)
                    }}
                  />
                </div>
                <div className="h-full">
                  <LeadDataContainer
                    text="WARM & HOT LEADS NOT CONTACTED IN 14+ DAYS"
                    number={leadDashboard?.lead_status_count}
                    className="min-h-[8rem] w-full"
                    onClick={() => {
                      setActiveTab(2)
                      setCurrentPage(1)
                      setFilterStatus({
                        this_week: false,
                        lead_not_contacted: true,
                      })
                      // Use setTimeout to ensure state is updated before the component re-renders
                      setTimeout(() => {
                        fetchLeads(filterData)
                      }, 0)
                    }}
                  />
                </div>
              </div>

              <div className="flex items-center gap-5">
                <CircleChart
                  Title="Lead Source"
                  TotalTitle="Total Leads"
                  Total={leadDashboard?.lead_source_count}
                  data={data}
                />
                <BarData
                  Title="Type of Lead"
                  BarGraphData={BarGraphData}
                  onBarClick={(leadType) => {
                    setActiveTab(2)
                    setCurrentPage(1)
                    const newFilterData = {
                      ...filterData,
                      lead_type: leadType,
                    }
                    setFilterData(newFilterData)
                    localStorage.setItem("filterData", JSON.stringify(newFilterData))
                    // Use setTimeout to ensure state is updated before fetching
                    setTimeout(() => {
                      fetchLeads(newFilterData)
                    }, 0)
                  }}
                />
              </div>
            </div>

            <div className="w-[35%]">
              <LeadDataContainer2
                text="HOT LEADS"
                data={leadDashboard?.lead_status_hot ?? []}
                columns={[
                  { key: "contact_name", label: "Contact Name" },
                  {
                    key: "link_name",
                    label: (
                      <>
                        Property/
                        <br />
                        Acquisition Criteria
                      </>
                    ),
                  },
                ]}
              />

              <LeadDataContainer2
                text="WARM LEADS"
                data={leadDashboard?.lead_status_warm ?? []}
                columns={[
                  { key: "contact_name", label: "Contact Name" },
                  {
                    key: "link_name",
                    label: (
                      <>
                        Property/
                        <br />
                        Acquisition Criteria
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}

      <AddLeadModal from="lead" showModal={showAddModal} onClose={() => setShowAddModal(false)} onCallApi={onSuccess} />

      <EditLeadModal
        showModal={showEditModal}
        onClose={() => setShowEditModal(false)}
        id={selectedId}
        onCallApi={() => {
          setSelectedId("")
          onSuccess()
        }}
        from="lead"
      />

      <ConvertPipelineModal
        showModal={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        handleConfirm={handleConfirm}
      />

      <DeleteConfirmationModal
        showModal={showDeleteModal}
        disable={disableDelete}
        onClose={() => setShowDeleteModal(false)}
        handleDelete={handleDelete}
      />
    </BaseLayout>
  )
}

export default Leads

