import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import Loader from "../../components/Loader";
import Plus from "../../assets/svgs/Plus.svg";
import LeadDataContainer from "../Leads/components/LeadDataContainer"
import CircleChart from "../../components/Charts/CircleChart"
import UpArrow from "../../assets/svgs/up-arrow.svg";
import DownArrow from "../../assets/svgs/down-arrow.svg";
import BarData from "../../components/Charts/BarData"
import { BASE_URL } from "../../utils/Element";
import BaseLayout from "../../layouts/BaseLayout";
import contact from "../../assets/svgs/account.svg";
import swap from "../../assets/svgs/swap-vertical.svg";
import ActionsMenu from "../../components/ActionsMenu";
import AddUnderContractModal from "./AddUnderContractModal";
import sortAsce from "../../assets/svgs/sort-ascending.svg";
import sortDesc from "../../assets/svgs/sort-descending.svg";
import EditUnderContractData from "./EditUnderContractModal";
import ContactPagination from "../../components/Pagination/ContactPagination";
import ConfirmationModal from "../../components/ConfirmationModals/ConfirmationModal";
import DeleteConfirmationModal from "../../components/ConfirmationModals/DeleteConfirmationModal";

const UnderContract = () => {
  const [config] = useAuth();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [listData, setListData] = useState([]);
  const [activeTab, setActiveTab] = useState(0)
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(50);
  const [sortBy, setSortBy] = useState("id");
  const [expandedRows, setExpandedRows] = useState({});
  const [sortDirection, setSortDirection] = useState("asc");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const isSidebarCollapsed = useSelector((state) => state.isSidebarCollapsed);
  const [paginationData, setPaginationData] = useState({ totalItems: 0, from: 0, to: 0, totalPages: 0 });

  const handleNext = () => {
    if (currentPage !== paginationData.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const fetchData = () => {
    axios
      .get(`${BASE_URL}/contract-listing?active_tab=${activeTab}page=${currentPage}&per_page=${itemPerPage}&search=${search}&sort_direction=${sortDirection}&sort_by=${sortBy}`, config)
      .then((res) => {
        const value = res?.data?.contract;
        setListData(value?.data || []);
        setPaginationData({
          totalItems: value?.total ?? 0,
          from: value?.from ?? 0,
          to: value?.to ?? 0,
          totalPages: value?.last_page ?? 0,
        });
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [sortDirection, sortBy]);

  useEffect(() => {
    onSuccess();
  }, [search, currentPage, itemPerPage , activeTab]);

  const onSuccess = () => {
    setLoading(true);
    fetchData();
  };

  const handleDelete = () => {
    setDisableDelete(true);
    axios
      .delete(`${BASE_URL}/delete-contract/${selectedId}`, config)
      .then((res) => {
        setSelectedId("");
        onSuccess();
        setShowDeleteModal(false);
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setDisableDelete(false));
  };

  const handleConfirm = () => {
    axios
      .post(`${BASE_URL}/mark-contract/${selectedId}`, { mark_contract: true }, config)
      .then((res) => {
        setSelectedId("");
        onSuccess();
        setShowConfirmModal(false);
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  const toggleRowDetails = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle only the clicked row
    }));
  };

 const BarGraphData = [
    { name: "Q1", count: 10 },
    { name: "Q2", count: 70 },
    { name: "Q3", count: 5 }, 
    { name: "Q4", count: 55 },  
  ];

  const BarGraphData2 = [
    { name: "Jan", count: 100 },
    { name: "Feb", count: 70 },
    { name: "Mar", count: 50 }, 
    { name: "Apr", count: 25 }, 
    { name: "May", count: "" },
    { name: "June", count: 25 },
    { name: "July", count: 5 }, 
    { name: "Aug", count: "" }, 
    { name: "Sep", count: 35 }, 
    { name: "Oct", count: 15 }, 
    { name: "Nov", count: "" }, 
    { name: "Dec", count: "" }, 
  ];

  return (
    <BaseLayout>
      <div className="contact-header">
        <p className="head-1 dark-H">Under Contract</p>
        <div className="md:flex block gap-6 items-center">
          <div className="flex gap-6 justify-between items-center">
            <div className="search-box contacts">
              <input
                type="text"
                className="body-S"
                placeholder="Search name, address, city, state..."
                value={search}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSearch(newValue.startsWith(" ") ? newValue.trimStart() : newValue);
                }}
                style={{ border: "1px solid #D8D8D8", marginTop: "0px" }}
              />
            </div>
            <button className="add-contact-button green-bg-H light-L body-S" onClick={() => setShowAddModal(true)}>
              <img className="mr-2 sidebar-icons" src={Plus} alt="plus" /> Add
            </button>
          </div>
        </div>
      </div>

      <div className="task-tabs mx-1 mt-2 flex justify-between body-L dark-M">
        <div className="flex gap-4">
          <p
            role="button"
            className={`${activeTab === 0 ? "head-4 dark-H active" : ""} pb-3`}
            onClick={() => {
              setActiveTab(0)
              setCurrentPage(1)
            }}
          >
              Team Dashboard
          </p>
          <p
            role="button"
            className={`${activeTab === 1 ? "head-4 dark-H active" : ""} pb-3`}
            onClick={() => {
              setActiveTab(1)
              setCurrentPage(1)
            }}
          >
            My Dashboard
          </p>
          <p
            role="button"
            className={`${activeTab === 2 ? "head-4 dark-H active" : ""} pb-3`}
            onClick={() => {
              setActiveTab(2)
              setCurrentPage(1)
            }}
          >
           Team Under Contract
           
          </p>
          <p
            role="button"
            className={`${activeTab === 3 ? "head-4 dark-H active" : ""} pb-3`}
            onClick={() => {
              setActiveTab(3)
              setCurrentPage(1)
            }}
          >
            My Under Contract
          </p>
        </div>
      </div>

      {(activeTab === 2 || activeTab === 3) && (
      <div className="light-bg-L pb-3 table-container">
        <div className={`${isSidebarCollapsed ? "collapsed-width" : "width"} table-info light-bg-L`}>
          {loading ? (
            <Loader />
          ) : listData?.length === 0 ? (
            <p className="body-N text-center mt-6 p-10">No Data Available</p>
          ) : (
            <table className="contact-table light-bg-L">
              <thead>
                <tr className="uppercase body-N dark-M">
                  <th>
                   <p>Details</p>
                  </th>
                  <th className="green-H ">
                    <div className="table-header">
                      Property Name
                      <img
                        role="button"
                        src={sortBy !== "property_name" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("property_name");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Address
                      <img
                        role="button"
                        src={sortBy !== "street_address" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("street_address");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      City
                      <img
                        role="button"
                        src={sortBy !== "city" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("city");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      State
                      <img
                        role="button"
                        src={sortBy !== "state" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("state");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Client
                      <img
                        role="button"
                        src={sortBy !== "client" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("client");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Deal Type
                      <img
                        role="button"
                        src={sortBy !== "lead_type" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("lead_type");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>

                  <th>
                    <div className="table-header">
                      Contract Date
                      <img
                        role="button"
                        src={sortBy !== "created_at" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("created_at");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>

                  <th>
                    <div className="table-header">
                      Noi
                      <img
                        role="button"
                        src={sortBy !== "anual_rent" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("anual_rent");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>

                  <th>
                    <div className="table-header">
                      Asking Cap Rate
                      <img
                        role="button"
                        src={sortBy !== "asking_cap_rate" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("asking_cap_rate");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>

                  <th>
                    <div className="table-header">
                      Asking Price
                      <img
                        role="button"
                        src={sortBy !== "asking_price" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("asking_price");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>

                  <th>
                    <div className="table-header">
                      Contract cap rate
                      <img
                        role="button"
                        src={sortBy !== "contract_cap_rate" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("contract_cap_rate");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Contract Price
                      <img
                        role="button"
                        src={sortBy !== "contract_price" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("contract_price");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      % Of Asking Price
                      <img
                        role="button"
                        src={sortBy !== "percent_of_asking_price" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("percent_of_asking_price");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Gross Commission <br /> To Company
                      <img
                        role="button"
                        src={sortBy !== "gross_commission_company" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("gross_commission_company");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Gross Commission <br /> To Agent
                      <img
                        role="button"
                        src={sortBy !== "gross_commission_agent" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("gross_commission_agent");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">
                      Gross Comission <br /> To Tri-Oak
                      <img
                        role="button"
                        src={sortBy !== "gross_commission_trioak" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                        onClick={() => {
                          setSortBy("gross_commission_trioak");
                          if (sortDirection === "desc") {
                            setSortDirection("asc");
                          } else {
                            setSortDirection("desc");
                          }
                        }}
                        alt="icon"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="table-header">More</div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {listData?.map((el, idx) => (
                       <React.Fragment key={idx}>
                       <tr key={idx} className="dark-H body-N">
                       <td>
                       <img 
                         role="button"
                         src={expandedRows[el.id] ? UpArrow : DownArrow}
                         alt="icon"
                         className="sidebar-icons"
                         onClick={() => toggleRowDetails(el.id)}
                       />
                     </td>
                       <td className="head-5 capitalize">
                          <Link
                            to={`/property/${el?.offer?.lead?.link?.id}`}
                            rel="noopener noreferrer"
                            onClick={(e) => {
                              e.preventDefault(); 
                              navigate(`/property/${el?.offer?.lead?.link?.id}`); 
                            }}
                          >
                            {el?.offer?.lead?.link?.property_name}
                          </Link>
                        </td>
    
                        <td className="dark-M capitalize">{el?.offer?.lead?.link?.street_address}</td>
                        <td>{el?.offer?.lead?.link?.city}</td>
                        <td>{el?.offer?.lead?.link?.state}</td>
                        <td className="flex items-center head-5 green-H gap-2 capitalize">
                        <Link
                          to={`/contact/${el?.offer?.lead?.link?.contact?.id}`}
                          rel="noopener noreferrer"
                          onClick={(e) => {
                            e.preventDefault(); 
                            navigate(`/contact/${el?.offer?.lead?.link?.contact?.id}`); 
                          }}
                          className="flex items-center" 
                        >
                          <img src={contact} alt="contact icon" className="sidebar-icons" />
                          {el?.offer?.lead?.link?.contact?.first_name} {el?.offer?.lead?.link?.contact?.last_name}
                        </Link>
                      </td>
    
                        <td>{el?.offer?.lead?.lead_type === 0 ? "Disposition" : "Acquisition"}</td>
                        <td>{moment(el?.created_at ," 'America/New_York'").format("MM/DD/YY")}</td>
                        <td className="text-center">{el?.offer?.lead?.link?.anual_rent ? `$${el?.offer?.lead?.link?.anual_rent?.toLocaleString()}` : ""}</td>
                        <td className="text-center">{el?.offer?.lead?.link?.asking_cap_rate ? `${el?.offer?.lead?.link?.asking_cap_rate?.toLocaleString()}%` : ""}</td>
                        <td className="text-center">{el?.offer?.lead?.link?.asking_price ? `$${el?.offer?.lead?.link?.asking_price?.toLocaleString()}` : ""}</td>
                        <td className="text-center">{el?.contract_cap_rate ? `${el?.contract_cap_rate?.toLocaleString()}%` : ""}</td>
                        <td className="text-center">{el?.contract_price ? `$${el?.contract_price?.toLocaleString()}` : ""}</td>
                        <td className="text-center">{el?.percent_of_asking_price ? `${el?.percent_of_asking_price?.toLocaleString()}%` : ""}</td>
                        <td className="text-center">{el?.gross_commission_company ? `$${el?.gross_commission_company?.toLocaleString()}` : ""}</td>
                        <td className="text-center">{el?.gross_commission_agent ? `$${el?.gross_commission_agent?.toLocaleString()}` : ""}</td>
                        <td className="text-center">{el?.gross_commission_trioak ? `$${el?.gross_commission_trioak?.toLocaleString()}` : ""}</td>
                        <td className="flex gap-3 items-center w-full justify-center">
                          <ActionsMenu
                            handleEdit={() => {
                              setShowEditModal(true);
                              setSelectedId(el?.id);
                            }}
                            handleDelete={() => {
                              setSelectedId(el?.id);
                              setShowDeleteModal(true);
                            }}
                            showOtherOption={true}
                            otherOptionTitle={"Close Contract"}
                            handleOtherOption={() => {
                              setShowConfirmModal(true);
                              setSelectedId(el?.id);
                            }}
                          />
                        </td>
                      </tr>
                 
                       {expandedRows[el.id] && (
                          <tr>
                            <td colSpan="11">
                            <div className="expanded-row-content p-4 bg-[rgb(244,244,244)] rounded-md">
                                <div className="">
    
                                <div className="flex items-center gap-5">
                               
                                  <div>
                                    <p className="dark-H head-4 mb-2">Seller Broker</p>
                                    <p className="body-N  dark-H">
                                      {el?.seller_broker?.first_name} {el?.seller_broker?.last_name || "N/A"}
                                    </p>
                                  </div>
                        
                                  <div>
                                    <p className="dark-H head-4 mb-2">Seller BDS</p>
                                    <p className="body-N  dark-H">
                                      {el?.seller_bds?.first_name} {el?.seller_bds?.last_name || "N/A"}
                                    </p>
                                  </div>
                        
                                  <div>
                                    <p className="dark-H head-4 mb-2">Buyer Broker</p>
                                    <p className="body-N  dark-H">
                                      {el?.buyer_broker?.first_name} {el?.buyer_broker?.last_name || "N/A"}
                                    </p>
                                  </div>
                      
                                  <div>
                                    <p className="dark-H head-4 mb-2">Buyer BDS</p>
                                    <p className="body-N  dark-H">
                                      {el?.buyer_bds?.first_name} {el?.buyer_bds?.last_name || "N/A"}
                                    </p>
                                  </div>
                                   
                                </div>
    
                                <div className="flex items-center gap-5 mt-4">
                                  
                                <div>
                                    <p className="dark-H head-4">Acquistion</p>
                                    <Link 
                                      to={`/contact/${el?.buyer_contact_id}`}
                                      className="body-N  dark-H "
                                      onClick={(e) => {
                                        e.preventDefault();
                                        navigate(`/contact/${el?.buyer_contact_id}`);
                                      }}
                                    >
                                      {el?.acquistion_name || "N/A"}
                                    </Link>
                                  </div>
    
                                  <div>
                                    <p className="dark-H head-4">Disposition</p>
                                    <Link 
                                      to={`/contact/${el?.seller_contact_id}`}
                                      className="body-N  dark-H "
                                      onClick={(e) => {
                                        e.preventDefault();
                                        navigate(`/contact/${el?.seller_contact_id}`);
                                      }}
                                    >
                                      {el?.disposition_name || "N/A"}
                                    </Link>
                                  </div>
    
                                  <div>
                                    <p className="dark-H head-4 mb-2">Asking Cap Rate</p>
                                    <p className="body-N  dark-H ">
                                      {el?.property?.asking_cap_rate ? `${el.property.asking_cap_rate.toLocaleString()} %` : "N/A"}
                                    </p>
                                  </div>
    
                                  <div>
                                    <p className="dark-H head-4 mb-2">Asking Price</p>
                                    <p className="body-N  dark-H ">
                                      {el?.property?.asking_price ? `$ ${el.property.asking_price.toLocaleString()}` : "N/A"}
                                    </p>
                                  </div>
    
                                 </div>
                                  
                               </div>
                              </div>
                            </td>
                          </tr>
                        )}
                     </React.Fragment>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <ContactPagination
          paginationData={paginationData}
          activeTab={activeTab}
          handleNext={handleNext}
          handlePrev={handlePrev}
          onSuccess={onSuccess}
          itemPerPage={itemPerPage}
          handleItemPerPageSelect={(value) => {
            setItemPerPage(value);
            setCurrentPage(1);
          }}
        />
      </div>
      )}

       {(activeTab === 0 || activeTab === 1) && (
        <>
         <div className="flex gap-3">
        <div className="w-[70%]">
           <div className="flex items-center gap-3">
           <LeadDataContainer text= "NEW UNDER CONTRACT" number="2"  />
           <LeadDataContainer text= "TOTAL TRANSACTIONS UNDER CONTRACT" number="3"  />
           <LeadDataContainer text= "BUYER TRANSACTIONS UNDER CONTRACT" number="5"  />
           <LeadDataContainer text= "SELLER TRANSACTIONS UNDER CONTRACT" number="4"  />
           </div>
           <div className="flex gap-4 items-center">
           <BarData 
              Title = "GROSS HOPPER GAIN BY QUARTER"
              BarGraphData={BarGraphData2}
              />

              <BarData 
              Title = "GCI CLOSING PROJECTIONS"
              BarGraphData={BarGraphData}
              />
           </div>
        </div>

          <div className="w-[30%] ">  
           <LeadDataContainer text= "UNDER CONTRACT GCI TO COMPANY" number="$38,025"  />
           <LeadDataContainer text= "AVG. COMMISSION PER TRANSACTION" number="$1,901,239"  />
           <LeadDataContainer text= "BUYER INTIAL OFFER TO UC CONVERSION RATE" number="50%"  />
           <LeadDataContainer text= "EXCLUSIVE TO UC CONVERSION RATE" number="50%"  />
          </div>

        </div>
        
        </>
       )}

      <AddUnderContractModal showModal={showAddModal} onClose={() => setShowAddModal(false)} onCallApi={onSuccess} />

      <EditUnderContractData
        showModal={showEditModal}
        onClose={() => setShowEditModal(false)}
        id={selectedId}
        onCallApi={() => {
          setSelectedId("");
          onSuccess();
        }}
      />

      <ConfirmationModal showModal={showConfirmModal} onClose={() => setShowConfirmModal(false)} handleConfirm={handleConfirm} />

      <DeleteConfirmationModal showModal={showDeleteModal} disable={disableDelete} onClose={() => setShowDeleteModal(false)} handleDelete={handleDelete} />
    </BaseLayout>
  );
};

export default UnderContract;
