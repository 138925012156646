import React, { useEffect, useRef, useState } from "react";
import RightModal from "../../components/Modal/RightModal";
import { countNonMatchingEntries, handleDropdownClose } from "../../utils/utils";
import SavedFilterComponent from "../../components/SavedFilters/SavedFilterComponent";
import { initialPipelineFilterData } from "../../utils/initialData";
import PipelineFilterData from "./Component/PipelineFilterData";

const PipelineFilter = ({ filterData, onSetFilterData, onCallApiAgain }) => {
  const dropdownRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedSavefilterId, setSelectedSaveFilterId] = useState(() => {
    const id = localStorage.getItem("selectedSavefilterId");
    return id ? id : "";
  });

  const count = countNonMatchingEntries(filterData, initialPipelineFilterData);

  useEffect(() => {
    const handleClose = () => {
      setIsSidebarOpen(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, []);

  const handleFilter = () => {
    localStorage.setItem("filterData", JSON.stringify(filterData));
    onCallApiAgain();
    setIsSidebarOpen(false);
    setSelectedSaveFilterId("");
  };

  const handleSelectFilter = (value) => {
    const dataToSet = {
      Pipeline_name: value?.Pipeline_name ?? "",
      contact_id: value?.contact_id,
      associate_id: value?.associate_id,
      transaction_type: value?.transaction_type,
      stage: value?.stage,
      min_potential_gcl: value?.min_potential_gcl,
      max_potential_gcl: value?.max_potential_gcl,
    };
    localStorage.setItem("filterData", JSON.stringify(dataToSet));
    onSetFilterData(dataToSet);
    onCallApiAgain(dataToSet);
  };

  const saveSearchFilterData = {
    Pipeline_name: filterData?.Pipeline_name,
    contact_id: filterData?.contact_id,
    associate_id: filterData?.associate_id,
    transaction_type: filterData?.transaction_type,
    stage: filterData?.stage,
    min_potential_gcl: filterData?.min_potential_gcl,
    max_potential_gcl: filterData?.max_potential_gcl,

  };

  return (
    <div>
      <SavedFilterComponent
        from="pipeline"
        count={count}
        isSidebarOpen={isSidebarOpen}
        saveSearchFilterData={saveSearchFilterData}
        selectedSavefilterId={selectedSavefilterId}
        initialFilterData={initialPipelineFilterData}
        onCallApiAgain={onCallApiAgain}
        onSetFilterData={onSetFilterData}
        onSetSelectedSaveFilterId={(value) => {
          setSelectedSaveFilterId(value);
        }}
        handleSelectFilter={handleSelectFilter}
        onSetIsSidebarOpen={(value) => setIsSidebarOpen(value)}
      />

      <RightModal
        isOpen={isSidebarOpen}
        onClose={() => {
          setIsSidebarOpen(false);
          onSetFilterData(initialPipelineFilterData);
        }}
        ref={dropdownRef}
        title={"Filter"}
        desc={"Enter Pipline information."}
        onFilter={handleFilter}
        from="pipeline"
        saveSearchFilterData={saveSearchFilterData}
        onSetSelectedSaveFilterId={(value) => setSelectedSaveFilterId(value)}
      >
        <PipelineFilterData isSidebarOpen={isSidebarOpen} filterData={filterData}  onSetFilterData={onSetFilterData} />
      </RightModal>
    </div>
  );
};

export default PipelineFilter;
