import React, { useEffect, useRef, useState } from "react";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { AiOutlineClose as CrossIcon } from "react-icons/ai";
import DatePicker from "react-datepicker";
import axios from "axios";
import { BASE_URL } from "../../../utils/Element";
import moment from "moment";
import Select from "react-select";
import text from "../../../assets/svgs/chat.svg";
import call from "../../../assets/svgs/call 2.svg";
import email from "../../../assets/svgs/send-email.svg";
import account from "../../../assets/svgs/account.svg";
import { handleDropdownClose } from "../../../utils/utils";
import { NotificationManager } from "react-notifications";
import LinkContactsModal from "../modals/LinkContactsModal";
import { priorityOptions, taskRepeatOptions } from "../../../utils/options";
import useAuth from "../../../hooks/useAuth";
import { useSelector } from 'react-redux';
import LinkCompaniesModal from "../modals/LinkCompaniesModal";


const AddTaskData = ({ taskData, error, formData, handleInputChange, from, type, data, selectedContacts, selectedCompanies, handleSelectedContacts, handleSelectedCompany}) => {
  const dropdownRef = useRef(null);
  const [config] = useAuth();
  const dropdownRepeatRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenTask, setIsOpenTask] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenCompanyModal, setIsOpenCompanyModal] = useState(false);
  const [bdsOptions, setBdsOptions] = useState([]);
  const selectRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const selectedPriorityOption = priorityOptions.find((el) => el.value === taskData?.priority);
  const userType = useSelector((state) => state.userType);



  useEffect(() => {
    const handleClose = () => {
      setIsOpen(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, []);

  useEffect(() => {
    const handleClose = () => {
      setIsOpenTask(false);
    };
    handleDropdownClose(dropdownRepeatRef, handleClose);
  }, []);

  const handleRemove = (id) => {
    const filteredData = selectedContacts.filter((el) => el.id !== id);
    handleSelectedContacts(filteredData);
  };

  const handleRemoveCompany = (id) => {
    const filteredData = selectedCompanies.filter((el) => el.id !== id);
    handleSelectedCompany(filteredData);
  };

  const fetchUsers = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/get-datalisting?list=users`, config)
      .then((res) => {
        const data = res?.data?.data;
        const options = data?.map((el) => ({ value: el?.id, label: `${el?.first_name} ${el?.last_name}`, role: el?.role_id }));
        setBdsOptions(options);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div>

      <div>
        <label className="dark-H head-4 mb-2">
          Task Name <span className="red-D">*</span>
        </label>
        <input
          className="body-N capitalize"
          name="task_name"
          type="text"
          placeholder="write your task name here"
          value={taskData.task_name}
          onChange={(e) => {
            handleInputChange(e.target.value, "task_name");
          }}
        />
        {error?.task_name && <span className="body-S red-D">{error.task_name}</span>}
      </div>

      
     {[1,2].includes(userType) && (
        <div className="mt-4">
          <label className="dark-H head-4 mb-2">
            Users <span className="red-D">*</span>
          </label>
          <Select
            ref={selectRef}
            className="body-N"
            options={bdsOptions}
            placeholder="Select"
            value={bdsOptions.find((option) => option?.value === taskData?.bds) || null}
            onChange={(option) => {
              handleInputChange(option?.value, "bds");
            }}
          />
        </div>
      )}

        {from === "contact" || from === "property" ? (
         <div role="button" className="head-5 green-H flex items-center gap-2 tags mt-2">
            <img src={account} alt="" className="sidebar-icons" /> {`${data?.first_name} ${data?.last_name}`}
          </div>
        ) : from === "company"  ? (
         <div role="button" className="head-5 compny green-H flex items-center gap-2 tags mt-2">
            <img src={account} alt="" className="sidebar-icons" /> {data?.company_name}
        </div>
       ) : (
       <div>
        {selectedContacts?.length > 0 && (
          <div className="flex flex-wrap gap-2 mt-2">
            {selectedContacts.map((el, index) => (
              <div key={index} className="head-5 green-H flex items-center gap-2 tags p-2 rounded-full bg-light-bg-L dark:bg-dark-bg-D">
                <img src={account} alt="" className="sidebar-icons" /> {el?.name} 
                <CrossIcon role="button" onClick={() => handleRemove(el.id)} />
              </div>
            ))}
          </div>
        )}
        {selectedCompanies?.length > 0 && (
          <div className="flex flex-wrap gap-2 mt-2">
            {selectedCompanies.map((el, index) => (
              <div key={index} className="head-5 green-H flex items-center gap-2 tags p-2 rounded-full bg-light-bg-L dark:bg-dark-bg-D">
                <img src={account} alt="" className="sidebar-icons" /> {el?.name}
                <CrossIcon role="button" onClick={() => handleRemoveCompany(el.id)} />
              </div>
            ))}
          </div>
        )}
        <div className="flex gap-4 mt-2">
          <div
            role="button"
            onClick={() => setIsOpenModal(true)}
            className="head-5 green-H flex items-center gap-2 tags"
          >
            <img src={account} alt="" className="sidebar-icons" /> Link Contact
          </div>
          <div
            role="button"
            onClick={() => setIsOpenCompanyModal(true)}
            className="head-5 green-H flex items-center gap-2 tags"
          >
            <img src={account} alt="" className="sidebar-icons" /> Link Company
          </div>
        </div>
       </div>
     )}
 
      <div className="mt-6">
        <p className="head-4 dark-H">
          Task Type <span className="red-D">*</span>
        </p>
        <div className="mt-3 capitalize">
          <RadioGroup
            onChange={(value) => {
              handleInputChange(value, "task_type");
            }}
            value={taskData.task_type}
          >
            <Stack direction="row" justifyContent="space-between" gap={5}>
              {["call", "text", "Email" , "Mail" , "Note"]?.flatMap((el, idx) => (
                <Radio key={idx} value={el}>
                  <div className="flex items-center gap-2">
                    <p>{el}</p>
                    {el === "call" && <img src={call} alt="" className="sidebar-icons" />}
                    {el === "text" && <img src={text} alt="" className="sidebar-icons" />}
                    {el === "Email" && <img src={email} alt="" className="sidebar-icons" />}
                    {el === "Mail" && <img src={email} alt="" className="sidebar-icons" />}
                    {el === "Note" && <img src={text} alt="" className="sidebar-icons" />}
                  </div>
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </div>

        {error?.task_type && <span className="body-S red-D">{error.task_type}</span>}
      </div>

      <div className="flex-1 mt-6">
        <p className="head-4 dark-H">
          Description <span className="body-S dark-M">(optional)</span>
        </p>
        <textarea
          rows={4}
          placeholder="Enter Description Here..."
          className="mt-2 w-full body-N"
          name="description"
          value={taskData.description}
          onChange={(e) => {
            handleInputChange(e.target.value, "description");
          }}
        />
        {error?.description && <span className="body-S red-D">{error.description}</span>}
      </div>

      <div className="mt-6">
        <label className="dark-H head-4 mb-2">
          Hyperlink <span className="body-S dark-M">(optional)</span>
        </label>
        <input
          className="body-N"
          name="hyperlink"
          type="text"
          placeholder="Add Optional Link..."
          value={taskData.hyperlink}
          onChange={(e) => {
            handleInputChange(e.target.value, "hyperlink");
          }}
        />
        {error?.hyperlink && <span className="body-S red-D">{error.hyperlink}</span>}
      </div>

      <div className="flex gap-3 mt-6">
        <div className="w-[50%] pl-0.5">
          <p className="head-4 dark-H">Priority Level</p>
          <div ref={dropdownRef} className="custom-dropdown mt-[6px]">
            <div role="button" className="select-header-input capitalize light-bg-L body-N dark-M flex justify-between items-center" onClick={() => setIsOpen(!isOpen)}>
              <p className="flex" style={{ color: selectedPriorityOption?.color }}>
                <img src={selectedPriorityOption?.icon} alt="" className="mr-2 sidebar-icons" /> {selectedPriorityOption?.label}
              </p>
            </div>

            {isOpen && (
              <div className="dropdown-list-container light-bg-L dark-M body-N shadow rounded-box" style={{ width: "100%" }}>
                <ul className="dropdown-list">
                  {priorityOptions.flatMap((el, i) => (
                    <li
                      key={i}
                      role="button"
                      onClick={() => {
                        handleInputChange(el.value, "priority");
                        setIsOpen(false);
                      }}
                      className={`${taskData.priority === el.value ? "active" : ""}`}
                      style={{ color: el.color }}
                    >
                      <img src={el.icon} alt="" className="mr-2 sidebar-icons" /> {el.label}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {error?.priority && <span className="body-S red-D">{error.priority}</span>}
        </div>

        <div className="w-[50%]">
          <label className="head-4 dark-H">
            Due Date <span className="red-D">*</span>
          </label>
          <DatePicker
            className="body-N"
            dateFormat="MM/dd/yyyy"
            placeholderText="MM/DD/YYYY"
            selected={taskData.due_date ? new Date(taskData.due_date) : null}
            onChange={(date) => {
              console.log('Selected date:', date);
              const dateInNYTime = moment(date).tz('America/New_York').format();
              handleInputChange(dateInNYTime, "due_date");
            }}
          />

          
          {error?.due_date && <span className="body-S red-D">{error.due_date}</span>}
        </div>
      </div>

      <div className="mt-6">
        <div className="w-[100%] pl-0.5">
          <p className="head-4 dark-H">Repeat</p>
          <div ref={dropdownRepeatRef} className="custom-dropdown mt-[6px]">
            <div role="button" className="select-header-input capitalize light-bg-L body-N dark-M flex justify-between items-center" onClick={() => setIsOpenTask(!isOpenTask)}>
              {taskData.repeat === "" ? "Select" : taskData.repeat === "custom" ? moment(taskData.repeat_date).format("MM/DD/YYYY") : taskRepeatOptions.find((el) => el.value === taskData.repeat)?.label}
            </div>

            {isOpenTask && (
              <div className="dropdown-list-container light-bg-L dark-M body-N shadow rounded-box" style={{ width: "100%", bottom: "40px" }}>
                <ul className="dropdown-list">
                  {taskRepeatOptions.flatMap((el, i) => (
                    <li
                      key={i}
                      role="button"
                      onClick={() => {
                        handleInputChange(el.value, "repeat");
                        if (el.value !== "custom") {
                          setIsOpenTask(false);
                        }
                      }}
                      className={`${taskData.repeat === el.value ? "active" : ""}`}
                    >
                      {el.label}
                    </li>
                  ))}
                  {taskData.repeat === "custom" && (
                    <li>
                      <DatePicker
                        className="body-N"
                        dateFormat="MM/dd/yyyy"
                        placeholderText="MM/DD/YYYY"
                        minDate={new Date()}
                        selected={taskData.repeat_date}
                        onChange={(date) => {
                          handleInputChange(date, "repeat_date");
                          setIsOpenTask(false);
                        }}
                      />
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        {error?.repeat && <span className="body-S red-D">{error.repeat}</span>}
      </div>

      <LinkContactsModal
        showModal={isOpenModal}
        selectedIds={type === "add" ? selectedContacts : []}
        onClose={() => {
          setIsOpenModal(false);
        }}
        onLink={handleSelectedContacts}
        from={from}
      />

       <LinkCompaniesModal
        showModal={isOpenCompanyModal}
        selectedIds={type === "add" ? selectedCompanies : []}
        onClose={() => {
          setIsOpenCompanyModal(false);
        }}
        onLink={handleSelectedCompany}
        from={from}
      />
    </div>
  );
};

export default AddTaskData;
